<div class="header"
     [class.expanded]="expanded">
    <div class="group-toggle"
         (click)="expanded = !expanded">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </div>{{ group.name }}
</div>
<div class="body"
     [class.expanded]="expanded">
    <div class="description"
         contenteditable="true"
         [ngModel]="description"
         (blur)="reattach()"
         (focus)="detachChange()"
         (ngModelChange)="contentChanged($event)"
         [class.is--empty]="!description.text">

    </div>
    <div class="action-points">
        <app-action-point *ngFor="let action of actionPoints; trackBy: actionPointTrackBy"
                          (update)="update($event)"
                          (select)="selectActionPoint.emit($event)"
                          (createNew)="createNew()"
                          [isActive]="isActive(action)"
                          [action]="action"></app-action-point>

        <div class="action-points__create">
            <a class="btn btn--icon-left btn--condensed"
               (click)="createActionPoint()">
                <div class="icon">
                    <mat-icon>add</mat-icon>
                </div>
                <span class="text">
                    Create action point
                </span>
            </a>
        </div>
    </div>
</div>