import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGroup } from '@contracts/models/IGroup';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { INote } from '@contracts/models/INote';
import { ISession } from '@contracts/models/ISession';

@Component({
  selector: 'app-session-points-group-list',
  templateUrl: './session-points-group-list.component.html',
  styleUrls: ['./session-points-group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [

    // nice stagger effect when showing existing elements
    trigger('list', [
      transition(':enter', [
        query('@items',
          stagger(50, animateChild())
          , { optional: true })
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)',
          style({ transform: 'scale(0.5)', opacity: 0, height: '0px' }))
      ])
    ]),
    trigger('items', [
      // cubic-bezier for a tiny bouncing feel
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)',
          style({ transform: 'scale(0.5)', opacity: 0, height: '0px' }))
      ]),
    ])
  ]
})
export class SessionPointsGroupListComponent implements OnInit {

  @Input() groups: IGroup[];
  @Input() points: IGroupPoint[];
  @Input() currentSession: ISession;
  @Input() groupedNotes: IGroupedNote[];

  @Output() addPoint = new EventEmitter<IGroup>();
  @Output() removePoint = new EventEmitter<IGroupPoint>();

  expandedGroups: IGroup[] = [];
  get availablePoints() {
    return 3;
  }
  groupTracker(_, group: IGroup) {
    return group.id;
  }
  get pointsByGroup() {
    return (this.points || []).reduce((res: { [groupId: string]: IGroupPoint[] }, gp: IGroupPoint) => {
      const currentPoints = res[gp.group.id] || [];
      return Object.assign({}, res, { [gp.group.id]: [...currentPoints, gp] });
    }, {});
  }

  get notesForGroup() {
    return (this.groupedNotes || []).reduce((res: { [groupId: string]: INote[] }, g: IGroupedNote) => {
      return Object.assign({}, res, { [g.group.id]: [...(res[g.group.id] || []), g.note] });
    }, {});
  }
  constructor() {

  }

  ngOnInit() {
  }

  isGroupExpanded(group: IGroup) {
    return this.expandedGroups.some(g => g.id === group.id);
  }
  toggleGroup(group: IGroup) {
    if (this.isGroupExpanded(group)) {
      this.expandedGroups = [...this.expandedGroups.filter(e => e.id !== group.id)];
    } else {
      this.expandedGroups = [...this.expandedGroups, group];
    }
  }

  togglePoint(group: IGroup, point) {
      if (this.pointsByGroup[group.id] && this.pointsByGroup[group.id][point]) {
          console.log(this.pointsByGroup[group.id][point]);
      this.removePoint.next(this.pointsByGroup[group.id][point]);
    } else {
      this.addPoint.next(group);
    }
  }
}
