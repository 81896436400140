<div class="session-present">
    <ng-container *ngIf="hasUnpresentedNotes$ | async; else done">
        <app-note-list
            [notes]="notes$ | async"
            [selectedNote]="selectedNote"
            [grouped]="true"
            positiveIcon="present_to_all"
            negativeIcon="present_to_all"
            (selectNote)="onSelectNote($event)"
        ></app-note-list>
    </ng-container>
    <ng-template #done>
        <div class="instructions">
            <p class="title">All done!</p>
            <p class="subtext"> Move on to the final page</p>
            <p style="margin-top: 2rem">
              <a [routerLink]="['..', 'points']" color="primary" mat-mini-fab class="with-content"> Points <mat-icon aria-label="">arrow_right_alt</mat-icon> </a>
            </p>
        </div>
    </ng-template>
    <div *ngIf="showIntro$ | async" @slideInOut>
        <div class="instructions">
            <p class="title">Wait for your turn...</p>
            <p class="subtext"> Once it's your turn, simply click the note you want to present from the list </p>
        </div>
    </div>
    <div *ngIf="showHelp$ | async" @slideInOut>
      <div class="instructions">
          <p class="title">You're up!</p>
          <p class="subtext"> Present one note at a time, by clicking them from the list above </p>
      </div>
  </div>
    <app-page-actions>
        <button mat-mini-fab color="primary" class="with-content" (click)="goBack()">
            <mat-icon aria-label="" class="rotate_180">arrow_right_alt</mat-icon> Back to notes
        </button>
        <a [routerLink]="['..', 'points']" color="primary" mat-mini-fab class="with-content"> Points <mat-icon aria-label="">arrow_right_alt</mat-icon> </a>
    </app-page-actions>
</div>

