import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISession } from '@contracts/models/ISession';
import { AuthService } from '@web/app/services/auth/auth.service';
import { formatDuration, intervalToDuration } from 'date-fns';

@Component({
    selector: 'app-session-list',
    templateUrl: './session-list.component.html',
    styleUrls: ['./session-list.component.scss']
})
export class SessionListComponent implements OnInit {
    @Input() showParticipating: boolean;
    @Input() sessions: ISession[];
    @Output() createNew = new EventEmitter();

    get mySessions(): ISession[] {
        return [...this.sessions.filter(this.isOwner)].sort((a, b) => {
            return a.dateCreated === undefined ? 1 : b.dateCreated === undefined ? -1 : new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
        });
    }

    get participatingSessions(): ISession[] {
        return this.sessions.filter(this.isParticipating);
    }
    constructor(private authService: AuthService) {}

    ngOnInit() {}

    readableDate = (date: Date) => {
        if (!date) {
            return '-';
        }
        const duration = intervalToDuration({ start: new Date(), end: new Date(date) });
        return `${formatDuration(duration, { format: ['days', 'months', 'weeks', 'hours', 'minutes', 'seconds'] })} ago`;
    };
    isOwner = (session: ISession) => {
        return session.owner.id === this.authService.user.id;
    };

    isParticipating = (session: ISession) => {
        return !this.isOwner(session);
    };
}

