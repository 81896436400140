import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NoteListModule } from '@web/app/components/note-list/note-list.module';
import { PageActionsModule } from '@web/app/components/page-actions/page-actions.module';
import { PostItModule } from '@web/app/components/post-it/post-it.module';
import { DirectivesModule } from '@web/app/directives/directives.module';
import { SessionParticipantComponent } from './session-participant.component';



@NgModule({
    declarations: [
        SessionParticipantComponent
    ],
    exports: [SessionParticipantComponent],
    imports: [
        FormsModule,
        CommonModule,
        MatIconModule,
        MatButtonModule,
        PageActionsModule,
        NoteListModule,
        PostItModule,
        DirectivesModule
    ]
})
export class SessionParticipantModule { }
