import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SessionQRCodeComponent } from './session-qrcode.component';

@NgModule({
  declarations: [SessionQRCodeComponent],
  imports: [
      CommonModule,
    NgxQRCodeModule,
  ],
  exports: [SessionQRCodeComponent]
})
export class SessionQRCodeModule { }
