import { ISession } from '@contracts/models/ISession';
import { IUser } from '@contracts/models/IUser';

export interface INote {
    id: string;
    owner: IUser;
    text: string;
    dateCreated: Date;
    kind: NoteKind;
    session: ISession;
    presented: boolean;
    timeUpdated: Date;
}

export enum NoteKind {
    positive = 'positive',
    negative = 'negative'
}

export namespace INote {
    export const noteKindSorter = (direction: 'desc' | 'asc') => (a: INote, b: INote) => {
        if (a.kind === NoteKind.positive && b.kind === NoteKind.positive) {
            return 0;
        }
        if (a.kind === NoteKind.positive && b.kind === NoteKind.negative) {
            return direction === 'desc' ? -1 : 1;
        }
        if (a.kind === NoteKind.negative && b.kind === NoteKind.positive) {
            return direction === 'desc' ? 1 : -1;
        }
        return 0;
    }
}