import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    template: `
   <div class="container">
        <div class="round" [class.checked]="checked">
            <label for="checkbox"></label>
        </div>
        </div>
  `,
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
    @Input() public checked: boolean;
    constructor() { }

    ngOnInit(): void {
    }

}
