import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from '@contracts/models/IUser';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
    @Input() public user: IUser;
    @Output() public logout = new EventEmitter();
    constructor() { }

    ngOnInit(): void {
    }

    getShortName(name: string) {
        const [first, ...rest] = name.split(' ');
        const [last, ..._] = [...rest].reverse();
        return `${first[0]}${(last?.[0] ?? '')}`;
    }
}
