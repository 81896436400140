import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISession } from '@contracts/models/ISession';
import { SessionService } from '@web/app/store/session.service';

@Component({
  selector: 'app-new-session',
  templateUrl: './new-session.component.html',
  styleUrls: ['./new-session.component.scss']
})
export class NewSessionComponent implements OnInit {

  constructor(private sessionService: SessionService, private router: Router) {
  }

  createSession() {
    this.sessionService.add(<ISession>{})
      .subscribe(session => this.router.navigate(['/', 'session', session.id, 'owner']));
  }


  ngOnInit() {
    this.createSession();
  }

}
