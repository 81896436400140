<mat-toolbar color="white"
             [class.is--hidden]="hidden$ | async"
             [class.is--presenter]="isPresenter$ | async">
    <div>
        <app-menu *ngIf="isOwner$ | async; else logo"
                  [currentSession]="currentSession$ | async"
                  [currentUser]="currentUser$ | async"
                  (logout)="onLogout()"></app-menu>

        <ng-template #logo>
            <div class="logo">
                retrospective.fun
            </div>
        </ng-template>
    </div>
    <div>
        {{ title$ | async }} <ng-container *ngIf="titleUrl$ | async as url">
            <button title="Copy Join-URL to clipboard"
            class="copy-button"
                    mat-icon-button
                    (click)="copyUrl(url)">
                <mat-icon>content_copy</mat-icon>
            </button></ng-container>
    </div>
    <div>
        <app-user-menu [user]="currentUser$ | async"
                       (logout)="onLogout()"></app-user-menu>

    </div>
</mat-toolbar>