import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@web/app/services/auth/auth.service';
import { SessionService } from '@web/app/store/session.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class EnsureSessionOwnerGuard implements CanActivate {
  constructor(private sessionService: SessionService,
    private location: Location,
    private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('ensure owner');
    return combineLatest(this.authService.user$, this.sessionService.getByKey(next.params.sessionId))
      .pipe(
        filter(([user, session]) => !!user && !!session),
        map(([user, session]) => user.id === session.owner.id),
        tap(isAuthorized => {
          if (!isAuthorized) {
            this.location.back();
          }
        }
        ));
  }
}
