import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: DataStoreModule
})
export class GroupPointService extends EntityCollectionServiceBase<IGroupPoint> {

  constructor(private http: HttpClient, elementsFactory: EntityCollectionServiceElementsFactory) {
    super('GroupPoint', elementsFactory);

  }


}
