import { INote } from '@contracts/models/INote';
import { ISession } from '@contracts/models/ISession';
import { Action } from '@ngrx/store';

export enum SocketActionTypes {
    NotePresented = '[Note] Note presented'
}

export class SocketAction implements Action {
    public type: string;
    public recepientRoom?: string;
}

export class NotePresented extends SocketAction {
    public type = SocketActionTypes.NotePresented;

    constructor(public note: INote, session: ISession) {
        super();
        this.recepientRoom = session.id;
    }
}
