import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INote } from '@contracts/models/INote';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityActionFactory, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityOp } from '@ngrx/data';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: DataStoreModule
})
export class NoteService extends EntityCollectionServiceBase<INote> {

  constructor(private http: HttpClient, elementsFactory: EntityCollectionServiceElementsFactory) {
    super('Note', elementsFactory);

  }

  addNote(note: INote) {
    const factory = new EntityActionFactory();

    return this.http.post<INote>(`/api/v1/note?sessionId=${note.session.id}`, note).pipe(
      map(n => factory.create('Note', EntityOp.QUERY_BY_KEY, n.id))
    ).subscribe(this.store);
  }
}
