<div class="list"
     @list>
  <ng-container *ngFor="let group of groups;trackBy:groupTracker;let i=index">
    <div class="list-item"
         @items>
      <button mat-icon-button
              class="mat-icon-button--has-text"
              style="background-color: white"
              [id]="'group-'+i"
              (click)="toggleGroup(group)">
        <mat-icon>{{ isGroupExpanded(group) ? 'expand_less' : 'expand_more' }}</mat-icon>
        <span> {{ group.name }}
        </span>
      </button>
      <div class="points"
           [id]="'group-points-'+i">
        <button mat-icon-button
                (click)="togglePoint(group, point)"
                *ngFor="let point of availablePoints | forloop; let index = index">
          <mat-icon [color]="pointsByGroup[group.id] && pointsByGroup[group.id][index] ? 'accent' : 'primary'">
            {{ pointsByGroup[group.id] && pointsByGroup[group.id][index] ? 'star' : 'star_border'}}
          </mat-icon>
        </button>
      </div>
    </div>
    <div class="list sub-list"
         @list
         *ngIf="isGroupExpanded(group)">
      <div class="list-item"
           @items
           *ngFor="let note of notesForGroup[group.id]">
        {{ note.text }}
      </div>
    </div>
  </ng-container>
</div>