<div class="session-whiteboard-groups"
     @list>
    <div *ngFor="let group of groups"
         class="group"
         @items>

        <div class="group_points"
             [@scaleAndFade]
             *ngIf="sessionMode === SessionMode.revealPoints">
            <span>{{ pointsByGroup[group.id] }} </span>
            <mat-icon color="">star</mat-icon>
        </div>
        <div class="group_header">{{ group.name }}</div>
        <div class="post-it-list stacked">
            <app-post-it *ngFor="let note of notesByGroup[group.id]"
                         [rotate]="false"
                         [theme]="note.kind === 'negative' ? 'dark' : 'light'"
                         class="small view force-width">
                {{ note.text }}
            </app-post-it>
        </div>
    </div>
</div>
