import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IGroup } from '@contracts/models/IGroup';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { ISession } from '@contracts/models/ISession';
import { GroupPointService } from '@web/app/store/group-point.service';
import { GroupService } from '@web/app/store/group.service';
import { GroupedNoteService } from '@web/app/store/grouped-note.service';
import { SessionService } from '@web/app/store/session.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-session-points',
  templateUrl: './session-points.component.html',
  styleUrls: ['./session-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionPointsComponent implements OnInit {

  groups$: Observable<IGroup[]>;
  points$: Observable<IGroupPoint[]>;
  currentSession$: Observable<ISession>;
  groupedNotes$: Observable<IGroupedNote[]>;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private groupService: GroupService,
    private groupedNotes: GroupedNoteService,
    private groupPointService: GroupPointService,
    private sessionService: SessionService
  ) {
    this.groups$ = groupService.entities$;
    this.points$ = groupPointService.entities$;
    this.currentSession$ = sessionService.getCurrentSession();
    this.groupedNotes$ = groupedNotes.entities$;
  }

  ngOnInit() {

  }
  goBack() {
    this.router.navigate(['..', 'present'], {
      relativeTo: this.activatedRoute
    });
  }


  onAddPoint(group: IGroup) {
    this.groupPointService.add(<IGroupPoint>{ group: group });
  }

  onRemovePoint(groupPoint: IGroupPoint) {
    this.groupPointService.delete(groupPoint);
  }
}
