import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InstructionComponent } from '@web/app/components/instruction/instruction.component';
import { InstructionsComponent } from './instructions.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InstructionComponent, InstructionsComponent],
  exports: [InstructionComponent, InstructionsComponent]
})
export class InstructionModule { }
