// An angular Pipe that returns a humanized date
//
// Usage:
// {{ date | readableDate }}
//
// Example:
// {{ new Date() | readableDate }}
// // => 'a few seconds ago'
//
// {{ new Date(2014, 7, 10) | readableDate }}
// // => '3 years ago'
//
// {{ new Date(2014, 7, 10) | readableDate:true }}
// // => '3 years, 1 month ago'
//

import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';

// {{ new Date(2014, 7, 10) | readableDate:false }}
@Pipe({ name: 'readableDate' })
export class ReadableDatePipe implements PipeTransform {
    transform(value: Date): string {
        if (!value) {
            return '-';
        }
        const duration = intervalToDuration({ start: new Date(), end: new Date(value) });
        return `${formatDuration(duration, { format: ['days', 'months', 'weeks', 'hours', 'minutes', 'seconds'] })} ago`;
    }
}
