import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Directive, ElementRef, Injectable, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ViewContainerService } from '@web/app/core/view-container.service';
import { sortBy } from 'lodash';

@Injectable({ providedIn: 'root' })
export class GuideService {
    private guides = new Map<string, Set<GuideDirective>>();
    public inProgress = [];
    register(guide: GuideDirective) {
        if (!this.guides.has(guide.guideName)) {
            this.guides.set(guide.guideName, new Set());
        }
        this.guides.get(guide.guideName).add(guide);
        this.checkPending(guide);
        this.start(guide.guideName);
    }

    checkPending(guide: GuideDirective) {
        if (this.pending.includes(guide.guideName)) {
            const allSteps = Array.from(this.guides.get(guide.guideName));
            const lastStep = allSteps.find(s => s.isLast);
            if (!lastStep) return;
            if (allSteps.length === lastStep.order + 1) this.start(guide.guideName);
        }
    }

    unregister(guide: GuideDirective) {
        if (this.guides.has(guide.guideName)) {
            this.guides.get(guide.guideName).delete(guide);
        }
    }

    back(guideStep: GuideDirective) {
        if (this.guides.has(guideStep.guideName) && guideStep.order > 0) {
            const steps = Array.from(this.guides.get(guideStep.guideName));
            const previousStep = sortBy(steps, s => s.order).find(s => s.order < guideStep.order);
            guideStep.deactive();
            const progress = ((previousStep.order + 1) * 100) / steps.length;
            previousStep.activate(progress);
        } else {
            this.inProgress.push(guideStep.guideName);
            guideStep.deactive();
        }
    }

    next(guideStep: GuideDirective) {
        if (this.guides.has(guideStep.guideName) && !guideStep.isLast) {
            const steps = Array.from(this.guides.get(guideStep.guideName));
            const nextStep = sortBy(steps, s => s.order).find(s => s.order > guideStep.order);
            guideStep.deactive();
            const progress = ((nextStep.order + 1) * 100) / steps.length;
            nextStep.activate(progress);
        } else {
            this.inProgress.push(guideStep.guideName);
            guideStep.deactive();
        }
    }

    private pending: string[] = [];

    start(guideName: string) {
        if (this.inProgress.includes(guideName)) return;
        console.log('will start if it exists', guideName);
        if (this.guides.has(guideName)) {
            console.log(this.inProgress);
            this.inProgress.push(guideName);

            this.pending = this.pending.filter(p => p !== guideName);
            console.log('it exists');
            const steps = Array.from(this.guides.get(guideName));
            const firstStep = sortBy(steps, s => s.order).find(s => !s.order);
            const progress = ((firstStep.order + 1) * 100) / steps.length;
            firstStep.activate(progress);
        } else {
            this.pending.push(guideName);
        }
    }
}

@Directive({
    selector: '[appGuide]'
})
export class GuideDirective implements OnInit, OnDestroy {
    constructor(private viewContainerRef: ViewContainerService, public elementRef: ElementRef, private guideService: GuideService) {}

    @Input() public appGuide: TemplateRef<any>;
    @Input() public guideName: string;
    @Input() public order: number;
    @Input() public isLast: boolean;
    private component;

    public next() {
        this.guideService.next(this);
    }
    public back() {
        this.guideService.back(this);
    }

    activate(progress: number): void {
        this.component = this.viewContainerRef.viewContainer.createComponent(GuideInstructionComponent, {});
        this.component.instance.appGuide = this.appGuide;
        this.component.instance.elementRef = this.elementRef;
        this.component.instance.guide = this;
        this.component.instance.progress = progress;
        this.component.changeDetectorRef.detectChanges();
    }

    deactive(): void {
        this.component.destroy();
        this.component = null;
    }

    ngOnDestroy(): void {
        this.guideService.unregister(this);
    }

    ngOnInit(): void {
        this.guideService.register(this);
    }
}

@Component({
    host: {
        '(@fadeInOut.done)': 'captureDoneEvent($event)'
    },
    selector: 'app-guide-instruction',
    template: `<div class="overlay"></div>
        <div class="instruction-wrapper" #instruction [style.top.px]="instructionStyle?.top" [style.left.px]="instructionStyle?.left">
            <div class="instruction" [@fadeInOut]="ready">
                <ng-container *ngTemplateOutlet="appGuide"></ng-container>
                <div class="buttons">
                    <button (click)="back($event)" *ngIf="order > 0" class="back"><mat-icon>arrow_back</mat-icon></button>
                    <button (click)="next($event)" class="next"><span>Next</span><mat-icon>arrow_forward</mat-icon></button>
                </div>
                <div class="progress" [style.width.%]="progress"></div>
            </div>
        </div>`,

    styleUrls: ['./guide.directive.scss'],
    animations: [
        trigger('fadeInOut', [
            transition('* => true', [
                style({ opacity: 0, transform: 'translateX(-50px) translateY(50px) scale(0.4)' }),
                animate('0.3s ease-in', style({ opacity: 1, transform: 'scale(1)' }))
            ])
        ])
    ]
})
export class GuideInstructionComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public appGuide: TemplateRef<any>;
    @Input() public elementRef: ElementRef;
    @Input() public guide: GuideDirective;
    @Input() public progress: number;

    @ViewChild('instruction', { read: ElementRef }) private thisElement;
    public ready = false;

    constructor(private changeDetector: ChangeDetectorRef) {}
    public get order(): number {
        return this.guide.order;
    }
    public next($event) {
        $event.preventDefault();
        this.guide.next();
    }

    public back($event) {
        $event.preventDefault();
        this.guide.back();
    }

    private style: any = {};

    public ngOnInit(): void {
        this.style.zIndex = this.elementRef.nativeElement.style.zIndex;
        this.style.outline = this.elementRef.nativeElement.style.outline;

        this.elementRef.nativeElement.style.zIndex = 2002;
        this.elementRef.nativeElement.style.outline = '3px solid #00f2f2';
    }

    public ngOnDestroy(): void {
        this.elementRef.nativeElement.style.zIndex = this.style.zIndex;
        this.elementRef.nativeElement.style.outline = this.style.outline;
    }
    public captureDoneEvent() {
        console.log('animation done');
    }
    public ngAfterViewInit(): void {
        setTimeout(() => {
            const rect = this.elementRef.nativeElement.getBoundingClientRect();
            const me = this.thisElement.nativeElement.getBoundingClientRect();

            const { innerHeight, innerWidth } = window;
            const { top, left } = rect;
            let instructionTop = 0;
            let instructionLeft = 0;
            console.log(me, rect, innerHeight, innerWidth);

            if (top + rect.height + me.height > innerHeight) {
                instructionTop = top - me.height - 32;
            } else {
                instructionTop = top + rect.height + 32;
            }

            if (left + rect.left + me.width > innerWidth) {
                instructionLeft = left - me.width + rect.width;
            } else {
                instructionLeft = left - 16;
            }

            // Make sure we have room in the viewport to display the instruction
            this.instructionStyle = {
                top: instructionTop,
                left: instructionLeft
            };
            console.log(this.instructionStyle);
            this.ready = true;
            this.changeDetector.detectChanges();
        });
    }

    public instructionStyle;
}

