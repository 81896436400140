import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGroup } from '@contracts/models/IGroup';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { INote } from '@contracts/models/INote';
import { ISession, SessionMode } from '@contracts/models/ISession';
import { scaleAndFade } from '@web/app/core/animations';

@Component({
    selector: 'app-note-groups',
    templateUrl: './note-groups.component.html',
    styleUrls: ['./note-groups.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [scaleAndFade]
})
export class NoteGroupsComponent implements OnInit {
    @Input() public groups: IGroup[];
    @Input() public selectedNotes: INote[];
    @Input() public groupedNotes: IGroupedNote[];
    @Input() public session: ISession;
    @Input() public points: IGroupPoint[];
    @Output() public remove = new EventEmitter<IGroupedNote>();
    @Output() public createGroup = new EventEmitter<{ group: IGroup, notes: INote[] }>();
    @Output() public addToGroup = new EventEmitter<IGroup>();
    @Output() public selectGroup = new EventEmitter<[IGroup, boolean]>();

    constructor() { }

    ngOnInit(): void {
    }

    public get sortedGroups() {
        return [...this.groups].sort((a: IGroup, b: IGroup) => {
            if (this.displayPoints) {
                return this.pointsForGroup(b).length - this.pointsForGroup(a).length;
            } else {
                return this.sortAlphabetic(a.name, b.name);
            }
        });
    }

    sortAlphabetic(a: string, b: string, reverse: boolean = false): number {
        if (a < b) return reverse ? 1 : -1;
        else if (a > b) return reverse ? -1 : 1;
        else return 0;
    }

    public get mode(): SessionMode {
        return this.session?.mode;
    }

    public get isDiscussionMode() {
        return this.mode === SessionMode.discussion;
    }

    public get displayPoints() {
        return this.mode === SessionMode.revealPoints || this.mode === SessionMode.discussion;
    }

    public get hasGroups() {
        return (this.selectedNotes && this.selectedNotes.length > 0) || (this.groups && this.groups.length > 0);
    }
    public get hasSelectedNotes() {
        return this.selectedNotes && this.selectedNotes.length > 0;
    }

    public pointsForGroup(group: IGroup) {
        return (this.points || []).filter(p => p.group?.id === group?.id);
    }

    public notesForGroup(group: IGroup) {
        return this.groupedNotes.filter(n => n.group.id === group.id).map(g => g.note);
    }

    public removeNote([note, group]: [INote, IGroup]) {
        const groupedNote = this.groupedNotes.find(g => g.group.id === group.id && g.note.id === note.id);
        this.remove.emit(groupedNote);
    }

    groupTracker(_, group: IGroup) {
        return group.id;
    }

    public create(name: string) {
        console.log(name);
        const group = {
            name: name,
            session: this.session
        } as IGroup;
        this.createGroup.emit({ group, notes: this.selectedNotes });
    }
}
