import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import 'moment-duration-format';
import 'platform';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();

    Sentry.init({
        dsn: "https://511ad2926055473581ab7aec175d613a@o202252.ingest.sentry.io/5508907",
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ["localhost", "https://www.retrospective.fun"],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

platformBrowserDynamic().bootstrapModule(AppModule);
