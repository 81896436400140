import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@web/app/services/auth/auth.service';
import { SessionService } from '@web/app/store/session.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticatedGuard implements CanActivate {
  constructor(public router: Router, public sessionService: SessionService, public authService: AuthService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getCurrentUser()
      .pipe(
        map(user => !!user),
        tap(() => this.sessionService.load()),
        catchError(err => {
          this.router.navigate(['/', 'login'], {
            queryParams: {
              redirectUrl: state.url
            }
          });
          return of(false);
        })
      );
  }
}
