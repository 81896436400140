import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IParticipant } from '@contracts/models/IParticipant';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: DataStoreModule
})
export class ParticipantService extends EntityCollectionServiceBase<IParticipant> {

  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super('Participant', elementsFactory);
  }

  public getCurrentParticipant() {
    return this.filteredEntities$.pipe(
      map(e => e.length === 1 ? e[0] : null)
    );
  }

  join(sessionNumber: string): Observable<IParticipant> {
    return this.http.post<IParticipant>(`/api/v1/participant?sessionNumber=${sessionNumber}`, {})
      .pipe(
        tap(c => this.setFilter(c.id)),
        switchMap(c => this.getByKey(c.id)));
  }
}
