import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGroup } from '@contracts/models/IGroup';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { INote } from '@contracts/models/INote';
import { scaleAndFade } from '@web/app/core/animations';

@Component({
    selector: 'app-note-group',
    templateUrl: './note-group.component.html',
    styleUrls: ['./note-group.component.scss'],
    animations: [scaleAndFade]
})
export class NoteGroupComponent {
    @Input() public group: IGroup;
    @Input() public notes: INote[];
    @Input() public hasSelectedNotes: boolean;
    @Input() public displayPoints: boolean;
    @Input() public points: IGroupPoint[];
    @Input() public canSelectGroups: boolean;
    @Output() public addToThis = new EventEmitter<IGroup>();
    @Output() public removeNote = new EventEmitter<[INote, IGroup]>();
    @Output() public selectGroup = new EventEmitter<[IGroup, boolean]>();

    public expanded = false;

    public get selected(): boolean {
        return this.group.selectedForDiscussion;
    }

    public toggle() {
        this.expanded = !this.expanded;
    }
    public onRemoveNote(note: INote) {
        this.removeNote.emit([note, this.group]);
    }
}
