import { Injectable } from '@angular/core';
import { LocalStorageKey } from '@web/app/core/local-storage-key.enum';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    public localStorage: any;

    constructor(private cookieService: CookieService) {
        this.localStorage = localStorage;
    }

    public addOrUpdateString(key: LocalStorageKey, value: string): void {
        if (!key || !this.localStorage) {
            return;
        }
        if (key === LocalStorageKey.accessToken) {
            // this.cookieService.set(key, value, 14);
        } else {
            this.localStorage.setItem(key, value);
        }
    }

    public getString(key: LocalStorageKey): string {
        if (!this.localStorage) {
            return null;
        }

        if (key === LocalStorageKey.accessToken) {
            // return this.cookieService.get(key);
        } else {
            return this.localStorage[key];
        }
        return null;
    }

    public addOrUpdate<T>(key: LocalStorageKey, value: T): void {
        if (!key || !this.localStorage) {
            return;
        }

        this.localStorage.setItem(key, JSON.stringify(value));
    }

    public addToArray(key: LocalStorageKey, item: string): any {
        const existing = this.get<string[]>(key);
        const newArray = [...(existing || []).filter(e => e !== item), item];
        this.addOrUpdate<string[]>(key, newArray);
    }


    public getAll() {
        return this.localStorage || {};
    }



    public get<T>(key: LocalStorageKey): T {
        if (!this.localStorage) {
            return null;
        }

        const data = this.localStorage[key];
        return data ? JSON.parse(data) : null;
    }

    public remove(key: LocalStorageKey | string): any {
        if (!this.localStorage) {
            return null;
        }
        if (key === LocalStorageKey.accessToken) {
            this.cookieService.delete(key);
        } else {
            this.localStorage.removeItem(key);
        }

    }
}
