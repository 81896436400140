import { Component, Input, OnInit } from '@angular/core';
import { INote } from '@contracts/models/INote';

@Component({
  selector: 'app-presented-note',
  templateUrl: './presented-note.component.html',
  styleUrls: ['./presented-note.component.scss']
})
export class PresentedNoteComponent implements OnInit {
    @Input() public note: INote;
    @Input() public hasSelectedNotes: boolean;
    @Input() public isSelected: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
