<div class="create">
    <div class="new-retrospective">
        <div class="info">
            <div class="title">Create a new session</div>
            <div class="subtext">Start a new Retrospective by click the button below. Participants can join as soon as
                you've started the session</div>

        </div>

        <div class="actions">
            <a [routerLink]="['/', 'new']"
                    class="btn btn--white btn--raised btn--icon-left">
                <div class="icon">
                    <mat-icon>grade</mat-icon>
                </div>
                <span class="text">
                    Create a new session!
                </span>

            </a>
        </div>
    </div>
</div>
<div class="sidebar">
    <app-session-list [sessions]="sessions$ | async"
                      [showParticipating]="false"></app-session-list>
</div>