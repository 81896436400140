import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INote, NoteKind } from '@contracts/models/INote';

@Component({
    selector: 'app-note-list',
    templateUrl: './note-list.component.html',
    styleUrls: ['./note-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        // nice stagger effect when showing existing elements
        trigger('list', [transition(':enter', [query('@items', stagger(50, animateChild()), { optional: true })])]),
        trigger('items', [
            // cubic-bezier for a tiny bouncing feel
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px' }))
            ])
        ])
    ]
})
export class NoteListComponent {
    @Input() notes: INote[];
    @Input() selectedNote: INote;
    @Output() selectNote = new EventEmitter<INote>();
    @Input() grouped: boolean;
    @Input() positiveIcon = 'thumb_up';
    @Input() negativeIcon = 'thumb_down';
    @Input() viewOnly = false;

    NoteKind = NoteKind;
    get sortedNotes() {
        return [...this.notes].sort(INote.noteKindSorter('desc'));
    }

    get positiveNotes() {
        return this.notes.filter(n => n.kind === NoteKind.positive);
    }
    get negativeNotes() {
        return this.notes.filter(n => n.kind === NoteKind.negative);
    }
    constructor() {}

    noteTrackByFn(_, note: INote) {
        return note.id;
    }
}

