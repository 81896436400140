import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageActionsComponent } from './page-actions.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PageActionsComponent],
  exports: [PageActionsComponent]

})
export class PageActionsModule { }
