import { Injectable } from '@angular/core';

export interface IPlatformFeatures {
    layout?: string;
    manufacturer?: string;
    name?: string;
    product?: string;
    version?: number[];
    osFamily?: string;
    osArchitecture?: number;
    osVersion?: number[];
}

/*{
 description: 'Safari 13.0.3 on Apple iPhone (iOS 13.2.3)',
 layout: 'WebKit',
 manufacturer: 'Apple',
 name: 'Safari',
 prerelease: null,
 product: 'iPhone',
 ua: 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1',
 version: '13.0.3',
 os: {
 architecture: 32,
 family: 'iOS',
 version: '13.2.3',
 },
 }*/

export interface Platform {
    description: string;
    layout: string;
    manufacturer: string;
    name: string;
    prerelease: string;
    product: string;
    ua: string;
    version: string;
    os: Os;
}

interface Os {
    architecture: string;
    family: string;
    version: string;
}

@Injectable({ providedIn: 'root' })
export class PlatformService {

    constructor() {
    }

    public get platform(): Platform {
        console.log((<any>window).platform)
        return (<any>window).platform;
    }

    public hasFeatures(p: IPlatformFeatures, debug?: boolean) {
        const layoutMatch = !p.layout || p.layout === this.platform.layout;
        const manufacturerMatch = !p.manufacturer || p.manufacturer === this.platform.manufacturer;
        const nameMatch = !p.name || p.name === this.platform.name;
        const productMatch = !p.product || p.product === this.platform.product;

        const versionParts = this.platform.version.split('.');
        let versionMatch: boolean;
        if (!p.version) {
            versionMatch = true;
        } else {
            versionMatch = true;
            p.version.forEach((v, i) => {
                if (v && v !== Number(versionParts[i])) {
                    versionMatch = false;
                }
            });
        }

        const osVersionParts = this.platform.os.version.split('.');
        let osVersionMatch: boolean;
        if (!p.osVersion) {
            osVersionMatch = true;
        } else {
            osVersionMatch = true;
            p.osVersion.forEach((v, i) => {
                if (v && v !== Number(osVersionParts[i])) {
                    osVersionMatch = false;
                }
            });
        }

        const osFamilyMatch = !p.osFamily || p.osFamily === this.platform.os.family;

        if (debug) {
            console.log(
                'layoutMatch', layoutMatch,
                'manufacturerMatch', manufacturerMatch,
                'nameMatch', nameMatch,
                'productMatch', productMatch,
                'versionMatch', versionMatch,
                'osVersionMatch', osVersionMatch,
                'osFamilyMatch', osFamilyMatch,
                'platform', this.platform,
            );
        }

        return layoutMatch && manufacturerMatch && nameMatch && productMatch && versionMatch && osVersionMatch && osFamilyMatch;
    }

    public canUseWebPFormat() {
        return !this.hasFeatures({ osFamily: 'iOS', osVersion: [13] });
    }
}
