import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PostItComponent } from '@web/app/components/post-it/post-it.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PostItComponent],
  exports: [PostItComponent]
})
export class PostItModule { }
