import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { INote, NoteKind } from '@contracts/models/INote';

@Component({
    selector: 'app-presented-note-list',
    templateUrl: './presented-notes-list.component.html',
    styleUrls: ['./presented-notes-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresentedNoteListComponent implements OnInit {
    public NoteKind = NoteKind;
    @Input() groupLists: string[];
    @Input() notes: INote[];
    @Input() kind?: NoteKind;
    @Input() public selectedNotes: INote[];
    @Output() toggleKind = new EventEmitter<NoteKind>();
    @Output() delete = new EventEmitter<IGroupedNote>();
    @Output() select = new EventEmitter<INote>();

    ngOnInit() {
    }

    public get hasSelectedNotes() {
        return this.selectedNotes?.length > 0;
    }

    public isSelected(note: INote) {
        return this.selectedNotes && this.selectedNotes.includes(note);
    }
    public get orderedNotes() {
        return [...(this.notes || [])].sort(INote.noteKindSorter('asc'));
    }
    onToggleKind() {
        if (!this.kind) {
            this.toggleKind.next(NoteKind.positive);
        } else if (this.kind === NoteKind.positive) {
            this.toggleKind.next(NoteKind.negative);
        } else {
            this.toggleKind.next(null);
        }
    }
    drop(event: CdkDragDrop<IGroupedNote>) {
        if (event.previousContainer === event.container) {
            // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            this.delete.emit(event.item.data);
            // transferArrayItem(event.previousContainer.data,
            //                   event.container.data,
            //                   event.previousIndex,
            //                   event.currentIndex);
        }
    }
}
