import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckboxModule } from '@web/app/components/checkbox/checkbox.module';
import { PresentedNoteComponent } from './presented-note.component';



@NgModule({
  declarations: [PresentedNoteComponent],
  imports: [
      CommonModule,
      CheckboxModule
  ],
  exports: [PresentedNoteComponent]
})
export class PresentedNoteModule { }
