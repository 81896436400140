<button mat-icon-button
        [matMenuTriggerFor]="menu">
    <div class="avatar">
        {{ getShortName(user.name) }}
    </div>
</button>
<mat-menu #menu="matMenu">
    <button mat-button
            (click)="logout.emit()">
        <mat-icon>logout</mat-icon>
        <span>&nbsp;Log out</span>
    </button>
</mat-menu>