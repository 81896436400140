<ng-template #loading>
    <app-spinner></app-spinner>
</ng-template>
<ng-container *ngIf="!isBusy; else loading">
    <div class="welcome">
        <div class="title">Join a session</div>
        <div class="subtext">
            <div *ngIf="!prefilledSessionNumber; else prefilledInfo"
                >Fill out your name and session-number to join a session. The session-number is provided by the session owner
            </div>
        </div>
        <ng-template #prefilledInfo> Fill out your name to join this session </ng-template>
        <div *ngIf="runningSessions$ | async as runningSessions" class="running-sessions">
            <strong>You're already part of {{ runningSessions.length > 1 ? 'these active sessions' : 'this active session' }}, do you want to rejoin?</strong>
            <div *ngFor="let session of runningSessions" class="running-session">
                <a *ngFor="let session of runningSessions" [routerLink]="linkForSession(session)"> {{ session.name }}</a>
                <small>started {{ session.dateCreated | readableDate }}</small>
            </div>
        </div>
    </div>

    <div class="join-session">
        <form (submit)="join($event)">
            <div class="form-control-group">
                <div class="form-control form-control--block">
                    <mat-form-field>
                        <input class="input100" name="name" matInput [(ngModel)]="name" required type="text" placeholder="Your name" />
                    </mat-form-field>
                </div>
                <div class="form-control form-control--block" *ngIf="!prefilledSessionNumber">
                    <mat-form-field>
                        <input class="input100" name="sessionNumber" matInput required [(ngModel)]="sessionNumber" type="tel" placeholder="Session number" />
                    </mat-form-field>
                </div>
            </div>

            <div class="join-session_buttons">
                <div class="filler">&nbsp;</div>
                <div class="join-session_buttons__action">
                    <button mat-mini-fab type="submit" name="join" (click)="join($event)" class="with-content">
                        Join session <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                </div>
                <div class="filler">&nbsp;</div>
            </div>
            <ng-container *ngIf="!prefilledSessionNumber; else filler">
                <div class="form-separator">
                    <div class="border-fill"></div><span>or</span>
                    <div class="border-fill"></div>
                </div>
                <div class="form-control-group join-session-create-link">
                    <a [routerLink]="['/', 'login']" mat-mini-fab class="with-content">
                        <mat-icon>person</mat-icon>
                        <span class="text"> Log in </span>
                    </a>
                </div>
            </ng-container>
            <ng-template #filler>
                <div style="height: 10rem"></div>
            </ng-template>
        </form>
    </div>
</ng-container>

