import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ISession } from '@contracts/models/ISession';
import { IUser } from '@contracts/models/IUser';
import { concatLatestFrom } from '@ngrx/effects';
import { AuthService } from '@web/app/services/auth/auth.service';
import { SocketIoService } from '@web/app/services/socket/socket-io.service';
import { ActionPointService } from '@web/app/store/action-points.service';
import { GroupPointService } from '@web/app/store/group-point.service';
import { GroupService } from '@web/app/store/group.service';
import { GroupedNoteService } from '@web/app/store/grouped-note.service';
import { NoteService } from '@web/app/store/me.service';
import { ParticipantService } from '@web/app/store/participant.service';
import { PresenterService } from '@web/app/store/presenter.service';
import { SessionService } from '@web/app/store/session.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EnsureSessionGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private sessionService: SessionService,
        private socketService: SocketIoService,
        private noteService: NoteService,
        private groupService: GroupService,
        private groupedNoteService: GroupedNoteService,
        private presenterService: PresenterService,
        private groupPointService: GroupPointService,
        private participantService: ParticipantService,
        private actionPointService: ActionPointService
    ) {}

    private loadSessionData = (session: ISession, user: IUser) => {
        this.participantService.clearCache();
        this.noteService.clearCache();
        this.groupService.clearCache();
        this.groupedNoteService.clearCache();
        this.groupPointService.clearCache();
        this.groupService.getWithQuery({ sessionId: session.id });
        this.groupedNoteService.getWithQuery({ sessionId: session.id });
        this.participantService.getWithQuery({ sessionId: session.id });
        this.presenterService.getWithQuery({ sessionId: session.id });
        this.noteService.getWithQuery({ sessionId: session.id });
        this.groupPointService.getWithQuery({ sessionId: session.id });
        if (user.id === session.owner.id) {
            this.actionPointService.getWithQuery({ sessionId: session.id });
        }
        this.sessionService.setFilter(session.id);
    };

    private getFromStoreOrApi(sessionId: string): Observable<any> {
        let tried = false;
        return combineLatest([this.sessionService.entityMap$.pipe(map(e => e[sessionId])), this.sessionService.loading$]).pipe(
            filter(([_, loading]) => !loading),
            map(([session, _]) => session),
            tap((data: ISession) => {
                if (!data && !tried) {
                    this.sessionService.getByKey(sessionId);
                    tried = true;
                } else {
                    this.socketService.join(data.id, data.owner.id === this.authService.user.id);
                }
            }),
            filter((data: ISession) => !!data),
            concatLatestFrom(() => this.authService.user$),
            tap(([session, user]) => this.loadSessionData(session, user)),
            take(1)
        );
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.getFromStoreOrApi(next.params.sessionId).pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }
}

