import { Component, Input, OnInit } from '@angular/core';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { IGroup } from '@contracts/models/IGroup';
import { scaleAndFadeIn } from '@web/app/core/animations';

@Component({
    selector: 'app-session-summary',
    templateUrl: './session-summary.component.html',
    styleUrls: ['./session-summary.component.scss'],
    animations: [scaleAndFadeIn]
})
export class SessionSummaryComponent implements OnInit {
    @Input() public groups: IGroup[];
    @Input() public actionPoints: IActionPoint[];
    constructor() { }

    ngOnInit(): void {
    }

    groupTrackFn(_, group: IGroup)  {
        return group.id;
    }

    get selectedGroups() {
        return this.groups.filter(g => g.selectedForDiscussion);
    }

    actionPointsForGroup(group: IGroup) {
        return this.actionPoints.filter(a => a.group.id === group.id).filter(a => !!a.title);
    }
}
