import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
    OpenSnackbar = '[Layout] Open snackbar',
    SnackbarOpened = '[Layout] Snackbar opened',
    SnackbarClosed = '[Layout] Snackbar closed',
}

export class OpenSnackbarAction implements Action {
    public readonly type = LayoutActionTypes.OpenSnackbar;

    constructor(public text: string, public action?: string) { }
}


export class SnackbarOpenedAction implements Action {
    public readonly type = LayoutActionTypes.SnackbarOpened;

    constructor() { }
}

export class SnackbarClosedAction implements Action {
    public readonly type = LayoutActionTypes.SnackbarClosed;

    constructor() { }
}

export type LayoutActions = OpenSnackbarAction |
    SnackbarClosedAction |
    SnackbarOpenedAction;
