<ng-template #ungrouped>
  <div class="list list--simple list--square"
       @list>
    <div class="list-item"
         @items
         [class.presented]="note.presented && !viewOnly"
         [class.selected]="selectedNote && note.id === selectedNote.id"
         *ngFor="let note of sortedNotes;trackBy:noteTrackByFn"
         (click)="selectNote.next(note)">
      <span mat-line> {{ note.text}}</span>
      <mat-icon matSuffix
                [color]="note.kind === NoteKind.positive ? 'success' : 'warn'">
        {{ note.kind === NoteKind.positive ? positiveIcon : negativeIcon }}</mat-icon>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="grouped; else ungrouped">
  <div class="list list--simple list--square"
       @list>
    <div class="list-item"
         @items
         [class.presented]="note.presented && !viewOnly"
         [class.selected]="selectedNote && note.id === selectedNote.id"
         *ngFor="let note of negativeNotes;trackBy:noteTrackByFn"
         (click)="selectNote.next(note)">
      <span mat-line> {{ note.text}}</span>
      <mat-icon matSuffix
                [color]="note.kind === NoteKind.positive ? 'success' : 'warn'">
        {{ note.kind === NoteKind.positive ? positiveIcon : negativeIcon }}</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="list list--simple list--square"
       @list>
    <div class="list-item"
         @items
         [class.presented]="note.presented && !viewOnly"
         [class.selected]="selectedNote && note.id === selectedNote.id"
         *ngFor="let note of positiveNotes;trackBy:noteTrackByFn"
         (click)="selectNote.next(note)">
      <span mat-line> {{ note.text}}</span>
      <mat-icon matSuffix
                [color]="note.kind === NoteKind.positive ? 'success' : 'warn'">
        {{ note.kind === NoteKind.positive ? positiveIcon : negativeIcon }}</mat-icon>
    </div>
  </div>
</ng-container>
