import { Inject } from '@angular/core';
import { socketEvents } from '@server/socket/socket.events';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import * as io from 'socket.io-client';
import * as sioWildcard from 'socketio-wildcard';
import { SocketIoConfig, SOCKET_CONFIG_TOKEN } from './socket-io.config';

export class SocketIoService {
  subscribersCounter = 0;
  ioSocket: SocketIOClient.Socket;

  constructor(@Inject(SOCKET_CONFIG_TOKEN) config: SocketIoConfig) {
    const url: string = config.url || '';
    const options: any = config.options || {};
    this.ioSocket = io(url, options);
    const patch = sioWildcard(io.Manager);
    this.on('disconnect', () => {
      console.log('disconnected');
    });

    this.on('connect', (data) => {
      console.log('connect', data);
      setTimeout(() => {
        this.rejoinRooms();
      }, 1000);
    });
    patch(this.ioSocket);
  }
  private roomsCache: { room: string, isOwner: boolean }[] = [];
  join = (room: string, isOwner: boolean) => {
    this.roomsCache = [...this.roomsCache.filter(r => r.room !== room), { room, isOwner }];
    this.emit(socketEvents.JoinSession, { sessionId: room, isOwner: isOwner });
  }

  rejoinRooms() {
    this.roomsCache.forEach(item => this.join(item.room, item.isOwner));
  }
  on(eventName: string, callback: Function) {
    this.ioSocket.on(eventName, callback);
  }

  once(eventName: string, callback: Function) {
    this.ioSocket.once(eventName, callback);
  }

  connect() {
    return this.ioSocket.connect();
  }

  disconnect(close?: any) {
    return this.ioSocket.disconnect.apply(this.ioSocket, arguments);
  }

  emit(eventName: string, data?: any, callback?: Function) {
    return this.ioSocket.emit.apply(this.ioSocket, arguments);
  }

  removeListener(eventName: string, callback?: Function) {
    return this.ioSocket.removeListener.apply(this.ioSocket, arguments);
  }

  removeAllListeners(eventName?: string) {
    return this.ioSocket.removeAllListeners.apply(this.ioSocket, arguments);
  }

  /** create an Observable from an event */
  fromEvent<T>(eventName: string): Observable<T> {
    this.subscribersCounter++;
    return new Observable<any>((observer: any) => {
      this.ioSocket.on(eventName, (data: T) => {
        observer.next(data);
      });
      return () => {
        if (this.subscribersCounter === 1) {
          this.ioSocket.removeListener(eventName);
        }
      };
    }).pipe(share());
  }

  /* Creates a Promise for a one-time event */
  fromEventOnce<T>(eventName: string): Promise<T> {
    return new Promise<T>(resolve => this.once(eventName, resolve));
  }
}
