import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SocketIOEffects } from '@web/app/services/socket/socket-io.effects';
import { SocketIoConfig, SOCKET_CONFIG_TOKEN } from './socket-io.config';
import { SocketIoService } from './socket-io.service';

/** Socket factory */
export function SocketFactory(config: SocketIoConfig) {
    return new SocketIoService(config);
}

@NgModule({
    imports: [EffectsModule.forFeature([SocketIOEffects])]
})
export class SocketIoModule {
    static forRoot(config: SocketIoConfig): ModuleWithProviders<SocketIoModule> {
        return {
            ngModule: SocketIoModule,
            providers: [
                { provide: SOCKET_CONFIG_TOKEN, useValue: config },
                {
                    provide: SocketIoService,
                    useFactory: SocketFactory,
                    deps: [SOCKET_CONFIG_TOKEN]
                }
            ]
        };
    }
}
