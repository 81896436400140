import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, QueryList, ViewChildren } from '@angular/core';
import { INote, NoteKind } from '@contracts/models/INote';
import { ISession, SessionMode } from '@contracts/models/ISession';

export const customAnimation = trigger('presentedNote', [
    transition(
        ':leave',

        [
            animate(
                '.5s cubic-bezier(.79,-0.6,.51,.86)',
                keyframes([
                    style({ transform: 'perspective(400px) scale(2) rotateY({{rotateY}}deg) rotateX({{rotateX}}deg)', offset: 0.4 }),
                    style({ top: '{{y}}px', left: '{{x}}px', transform: 'perspective(400px) scale(1) rotateZ({{r}}deg) rotateY(0deg) rotateX(0deg)', offset: 1 })
                ])
            )
        ],
        {
            params: { x: 0, y: 0, r: 0, rotateX: 0, rotateY: 0 }
        }
    )
]);

@Component({
    selector: 'app-present-notes',
    templateUrl: './present-notes.component.html',
    styleUrls: ['./present-notes.component.scss'],
    animations: [
        customAnimation,
        // nice stagger effect when showing existing elements
        trigger('list', [transition(':enter', [transition(':enter', [])])]),
        trigger('items', [
            // cubic-bezier for a tiny bouncing feel
            transition('* => true', [
                style({ opacity: 0 }),
                animate('0.5s  cubic-bezier(.62,.91,.21,.93)', keyframes([style({ opacity: 0, offset: 0.999 }), style({ opacity: 1, offset: 1 })]))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresentNotesComponent implements OnChanges {
    public NoteKind = NoteKind;
    public SessionMode = SessionMode;
    @Input() positiveNotes: (INote & { rotation: number })[];
    @Input() negativeNotes: (INote & { rotation: number })[];
    @Input() presentedNote: INote & { rotation: number };
    @Input() currentSession: ISession;

    @ViewChildren('positive', { read: ElementRef }) positiveNotesElements: QueryList<ElementRef>;
    @ViewChildren('negative', { read: ElementRef }) negativeNotesElements: QueryList<ElementRef>;
    public get positive() {
        return this.presentedNote?.kind === NoteKind.positive ? [...this.positiveNotes.filter(p => p !== this.presentedNote), this.presentedNote] : this.positiveNotes;
    }

    public get negative() {
        return this.presentedNote?.kind === NoteKind.negative ? [...this.negativeNotes.filter(p => p !== this.presentedNote), this.presentedNote] : this.negativeNotes;
    }

    public noteFn(_: number, item: INote) {
        return item.id;
    }

    constructor(private changeDetector: ChangeDetectorRef) {}

    public endPosition: { x: number; y: number; r: number; rotateX: number; rotateY: number } = { x: 0, y: 0, r: 0, rotateX: 0, rotateY: 0 };
    public get hasEndposition() {
        return !!this.endPosition;
    }

    ngOnChanges(): void {
        setTimeout(() => {
            const noteElements = this.presentedNote?.kind === NoteKind.positive ? this.positiveNotesElements : this.negativeNotesElements;
            const last = noteElements?.last;
            if (last) {
                const presentedNoteElement = document.querySelector('.presented-note app-post-it');
                if (presentedNoteElement) {
                    const rect = presentedNoteElement.getBoundingClientRect();
                    const { x, y } = last.nativeElement.getBoundingClientRect();
                    const clientY = y;
                    const clientX = x;
                    const constraint = 20;
                    const rotateX = -(clientY - rect.y - rect.height / 2) / constraint;
                    const rotateY = (clientX - rect.x - rect.width / 2) / constraint;
                    this.endPosition = { x, y, r: this.presentedNote?.rotation, rotateX, rotateY };
                    this.changeDetector.detectChanges();
                }
            }
        });
    }
}

