import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CheckboxModule } from '@web/app/components/checkbox/checkbox.module';
import { PresentedNoteModule } from '../presented-note-list/presented-note/presented-note.module';
import { NoteGroupComponent } from './note-group/note-group.component';
import { NoteGroupsComponent } from './note-groups.component';



@NgModule({
    declarations: [NoteGroupsComponent, NoteGroupComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        PresentedNoteModule,
        MatIconModule,
        MatButtonModule,
        CheckboxModule
    ],
    exports: [NoteGroupsComponent]
})
export class NoteGroupsModule { }
