import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'forloop' })
export class ForLoop implements PipeTransform {
    transform(value: number): any {
        const res = [];
        for (let i = 0; i < value; i++) {
            res.push(i);
        }
        return res;
    }
}

