<div class="page-wrapper">
  <div class="page-header">
    <app-header></app-header>
  </div>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
  <div class="page-footer">
    <app-footer></app-footer>
  </div>
</div>
