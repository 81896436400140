import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { IGroup } from '@contracts/models/IGroup';
import { ISession } from '@contracts/models/ISession';

@Component({
    selector: 'app-discuss',
    templateUrl: './discuss.component.html',
    styleUrls: ['./discuss.component.scss']
})
export class DiscussComponent implements OnInit {
    @Input() public groups: IGroup[];
    @Input() public session: ISession;
    @Input() public actionPoints: IActionPoint[];
    @Input() public activeActionPoint: IActionPoint;
    @Output() public descriptionChange = new EventEmitter<[IGroup, string]>();
    @Output() public addActionPoint = new EventEmitter<IActionPoint>();
    @Output() public saveActionPoint = new EventEmitter<IActionPoint>();
    @Output() public selectActionPoint = new EventEmitter<IActionPoint>();


    constructor() { }

    groupTrackBy(_, group: IGroup) {
        return group.id;
    }
    ngOnInit(): void {
    }

    public get selectedGroups() {
        return [...(this.groups || []).filter(g => g.selectedForDiscussion)].sort((a, b) => this.sortAlphabetic(a.name, b.name));
    }

    getActionPointsForGroup(group: IGroup) {
        return this.actionPoints.filter(a => a.group.id === group.id);
    }

    sortAlphabetic(a: string, b: string, reverse: boolean = false): number {
        if (a < b) return reverse ? 1 : -1;
        else if (a > b) return reverse ? -1 : 1;
        else return 0;
    }

    onAddActionPoint([group, actionPoint]: [IGroup, IActionPoint]) {
        this.addActionPoint.emit({
            ...actionPoint,
            group,
            session: this.session
        });
    }

}
