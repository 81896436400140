<div class="welcome">
    <div class="title">Log in</div>
    <div class="subtext">Sign up or log in to create a new session, or administer your previous sessions</div>
</div>
<div class="authenticate">
    <form (submit)="submitAuthentication($event)">
        <div class="form-control-group">
            <div class="form-control form-control--block" @control *ngIf="mode === 'signup'">
                <mat-form-field>
                    <input class="input100" name="name" matInput [(ngModel)]="name" type="text" placeholder="Your name" />
                </mat-form-field>
            </div>
            <div class="form-control form-control--block">
                <mat-form-field>
                    <input class="input100" name="email" matInput [(ngModel)]="email" type="text" placeholder="Email address" />
                </mat-form-field>
            </div>

            <div class="form-control form-control--block">
                <mat-form-field>
                    <input class="input100" type="password" name="password" matInput [(ngModel)]="password" placeholder="Password" />
                </mat-form-field>
            </div>
        </div>

        <div class="authenticate_buttons">
            <div class="filler">&nbsp;</div>
            <div class="authenticate_buttons__action">
                <button mat-mini-fab (click)="login($event)" class="with-content"> {{ mode === 'signin' ? 'Log in' : 'Sign up' }} <mat-icon>

                  {{ mode === 'signin' ? 'lock_open' : 'app_registration' }}</mat-icon> </button>
            </div>

            <div>
                <a
                    name="
                        sign-up"
                    (click)="signUp($event)"
                >
                    {{ mode === 'signin' ? 'Sign up' : 'Log in' }}
                </a>
            </div>
        </div>
        <div class="form-separator">
            <div class="border-fill"></div><span>or</span>
            <div class="border-fill"></div>
        </div>
        <div class="form-control-group authenticate-join-link">
            <a [routerLink]="['/', 'join']" mat-mini-fab class="with-content">
                <mat-icon>person</mat-icon>
                Join existing session
            </a>
        </div>
    </form>
</div>

