import {
    Directive,
    ElementRef,
    forwardRef, HostListener, Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type ContentEditableChangeEventArgs = { text: string, html: string, event: any };

@Directive({
    selector: '[contenteditable]',
    providers:
        [
            { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ContenteditableDirective), multi: true }
        ]
})
export class ContenteditableDirective implements ControlValueAccessor {
    private onChange: (value: ContentEditableChangeEventArgs) => void;
    private onTouched: () => void;
    private removeDisabledState: () => void;

    private get text() {
        return this.elementRef.nativeElement['textContent'];
    }

    private get html() {
        return this.elementRef.nativeElement['innerHTML'];
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

    @HostListener('input', ['$event'])
    callOnChange(event) {
        if (typeof this.onChange === 'function') {
            this.onChange({ text: this.text, html: this.html, event });
        }
    }

    @HostListener('focus')
    setCaretPosition() {
        if(this.text.length)
            this.setCurrentCursorPosition(this.text.length);
    }


    createRange(node: any, chars: any, range?: any) {
        if (!range) {
            range = document.createRange();
            range.selectNode(node);
            range.setStart(node, 0);
        }

        if (chars.count === 0) {
            range.setEnd(node, chars.count);
        } else if (node && chars.count > 0) {
            if (node.nodeType === Node.TEXT_NODE) {
                if (node.textContent.length < chars.count) {
                    chars.count -= node.textContent.length;
                } else {
                    range.setEnd(node, chars.count);
                    chars.count = 0;
                }
            } else {
                for (let lp = 0; lp < node.childNodes.length; lp++) {
                    range = this.createRange(node.childNodes[lp], chars, range);

                    if (chars.count === 0) {
                        break;
                    }
                }
            }
        }

        return range;
    };

    setCurrentCursorPosition(chars) {
        if (chars >= 0) {
            let selection = window.getSelection();

            const range = this.createRange(this.elementRef.nativeElement.parentNode, { count: chars });

            if (range) {
                range.collapse(false);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    };

    @HostListener('blur')
    callOnTouched() {
        if (typeof this.onTouched === 'function') {
            this.onTouched();
        }
    }

    /**
     * Writes a new value to the element.
     * This method will be called by the forms API to write
     * to the view when programmatic (model -> view) changes are requested.
     *
     * See: [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor#members)
     */
    writeValue(value: { text: string, html: string }): void {
        const normalizedValue = value == null ? { text: '', html: '' } : value.html;
        this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', normalizedValue);
    }

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     *
     * This is called by the forms API on initialization so it can update
     * the form model when values propagate from the view (view -> model).
     */
    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when the control receives a blur event.
     * This is called by the forms API on initialization so it can update the form model on blur.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * This function is called by the forms API when the control status changes to or from "DISABLED".
     * Depending on the value, it should enable or disable the appropriate DOM element.
     */
    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
            this.removeDisabledState = this.renderer.listen(this.elementRef.nativeElement, 'keydown', this.listenerDisabledState);
        } else {
            if (this.removeDisabledState) {
                this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
                this.removeDisabledState();
            }
        }
    }

    private listenerDisabledState(e: KeyboardEvent) {
        e.preventDefault();
    }
}