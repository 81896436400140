import { Component, OnInit, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISession } from '@contracts/models/ISession';
import { IUser } from '@contracts/models/IUser';
import { LocalStorageKey } from '@web/app/core/local-storage-key.enum';
import { LocalStorageService } from '@web/app/core/local-storage.service';
import { HeaderService } from '@web/app/layout/header/header.service';
import { AuthService } from '@web/app/services/auth/auth.service';
import { PresenterService } from '@web/app/store/presenter.service';
import { SessionService } from '@web/app/store/session.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    currentSession$: Observable<ISession>;
    currentUser$: Observable<IUser>;
    title$: Observable<string>;
    compact$: Observable<boolean>;
    isPresenter$: Observable<boolean>;
    hidden$: Observable<boolean>;
    isOwner$: Observable<boolean>;
    titleUrl$: Observable<string>;

    constructor(private localStorageService: LocalStorageService,
        headerService: HeaderService,
        private snackBar: MatSnackBar,
        presenterService: PresenterService,
        @Optional() private authService: AuthService,
        @Optional() sessionService: SessionService) {
        this.currentSession$ = sessionService && sessionService.getCurrentSession();
        this.currentUser$ = authService && authService.user$;
        this.title$ = headerService.header;
        this.hidden$ = headerService.options.pipe(map(o => o.hidden));
        this.titleUrl$ = headerService.titleUrl;

        this.isPresenter$ = combineLatest([presenterService.getPresenter(), authService.user$]).pipe(
            map(([presenter, user]) => presenter && presenter.user.id === user.id)
        );

        this.isOwner$ = combineLatest([this.currentSession$, this.currentUser$]).pipe(
            map(([session, user]) => session?.owner?.id === user?.id)
        );
    }

    ngOnInit() {

    }

    async copyUrl(url) {
        await navigator.clipboard.writeText(url);
        this.snackBar.open(`Link to join session was copied to your clipboard!`, 'Okay!', {
            duration: 3000
        });
    }

    onLogout() {
        this.authService.logout().subscribe(() => {
            this.localStorageService.remove(LocalStorageKey.accessToken);
            window.location.href = window.location.href;
        });
    }
}
