import { Component, Input } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
    selector: 'app-session-qrcode',
    template: `
   <ngx-qrcode
  [elementType]="elementType"
  [errorCorrectionLevel]="correctionLevel"
  [value]="value"
  cssClass="bshadow"></ngx-qrcode>
  <div class="info">Visit https://www.retrospective.fun/ to join this session</div>
  `,
    styles: [
        `:host {
            text-align: center;
        }`,
        `.bshadow {

            display: flex;
            align-items: center;
            justify-content: center;
            filter: drop-shadow(5px 5px 5px #222222);
            opacity: .5;

          }`,
        `.info {
            font-size: 0.9rem;
        }`
    ]
})
export class SessionQRCodeComponent {
    @Input() public sessionNumber;
    elementType = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
    public get value() {
        return `https://www.retrospective.fun/join?sn=${this.sessionNumber}`;
    }

}
