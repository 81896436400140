<div class="palette-container">
  <div class="palette">
    <div>Primary</div>
    <div class="palette_colors">
      <div class="palette_colors_group">
        <div class="palette_colors_group_color bg-primary-800">800</div>
        <div class="palette_colors_group_color bg-primary-700">700</div>
        <div class="palette_colors_group_color bg-primary-600">600</div>
        <div class="palette_colors_group_color bg-primary-500">500</div>
        <div class="palette_colors_group_color bg-primary-400">400</div>
        <div class="palette_colors_group_color bg-primary-300">300</div>
        <div class="palette_colors_group_color bg-primary-200">200</div>
      </div>
    </div>
  </div>
  <div class="palette">
    <div>Neutral</div>
    <div class="palette_colors">
      <div class="palette_colors_group">
        <div class="palette_colors_group_color bg-neutral-800">800</div>
        <div class="palette_colors_group_color bg-neutral-700">700</div>
        <div class="palette_colors_group_color bg-neutral-600">600</div>
        <div class="palette_colors_group_color bg-neutral-500">500</div>
        <div class="palette_colors_group_color bg-neutral-400">400</div>
        <div class="palette_colors_group_color bg-neutral-300">300</div>
        <div class="palette_colors_group_color bg-neutral-200">200</div>
      </div>
    </div>
  </div>
  <div class="palette">
    <div>Accents</div>
    <div class="palette_colors">
      <div class="palette_colors_group">
        <div class="palette_colors_group_color bg-accent-800">800</div>
        <div class="palette_colors_group_color bg-accent-700">700</div>
        <div class="palette_colors_group_color bg-accent-600">600</div>
        <div class="palette_colors_group_color bg-accent-500">500</div>
        <div class="palette_colors_group_color bg-accent-400">400</div>
        <div class="palette_colors_group_color bg-accent-300">300</div>
        <div class="palette_colors_group_color bg-accent-200">200</div>
      </div>
      <div class="palette_colors_group">
        <div class="palette_colors_group_color bg-success-800">800</div>
        <div class="palette_colors_group_color bg-success-700">700</div>
        <div class="palette_colors_group_color bg-success-600">600</div>
        <div class="palette_colors_group_color bg-success-500">500</div>
        <div class="palette_colors_group_color bg-success-400">400</div>
        <div class="palette_colors_group_color bg-success-300">300</div>
        <div class="palette_colors_group_color bg-success-200">200</div>
      </div>
      <div class="palette_colors_group">
        <div class="palette_colors_group_color bg-warn-800">800</div>
        <div class="palette_colors_group_color bg-warn-700">700</div>
        <div class="palette_colors_group_color bg-warn-600">600</div>
        <div class="palette_colors_group_color bg-warn-500">500</div>
        <div class="palette_colors_group_color bg-warn-400">400</div>
        <div class="palette_colors_group_color bg-warn-300">300</div>
        <div class="palette_colors_group_color bg-warn-200">200</div>
      </div>
      <div class="palette_colors_group">
        <div class="palette_colors_group_color bg-error-800">800</div>
        <div class="palette_colors_group_color bg-error-700">700</div>
        <div class="palette_colors_group_color bg-error-600">600</div>
        <div class="palette_colors_group_color bg-error-500">500</div>
        <div class="palette_colors_group_color bg-error-400">400</div>
        <div class="palette_colors_group_color bg-error-300">300</div>
        <div class="palette_colors_group_color bg-error-200">200</div>
      </div>
    </div>
  </div>
</div>
<button (click)="raiseException()">Test exception</button>