import { Routes } from '@angular/router';
import { UserAuthenticatedGuard } from '@web/app/core/user-authenticated.guard';
import { AuthenticateComponent } from '@web/app/pages/authenticate/authenticate.component';
import { AuthenticatedComponent } from '@web/app/pages/authenticated.component';
import { DashboardComponent } from '@web/app/pages/dashboard/dashboard.component';
import { PaletteComponent } from '@web/app/pages/palette/palette.component';
import { JoinSessionComponent } from '@web/app/pages/session-start/join-session/join-session.component';
import { NewSessionComponent } from '@web/app/pages/session-start/new-session/new-session.component';
import { EnsureSessionOwnerGuard } from '@web/app/pages/session/ensure-session-owner.guard';
import { EnsureSessionGuard } from '@web/app/pages/session/ensure-session.guard';
import { SessionOwnerComponent } from '@web/app/pages/session/session-owner/session-owner.component';
import { SessionParticipantComponent } from '@web/app/pages/session/session-participant/session-participant.component';
import { SessionPointsComponent } from '@web/app/pages/session/session-points/session-points.component';
import { SessionPresentComponent } from '@web/app/pages/session/session-present/session-present.component';
import { SessionWhiteboardComponent } from '@web/app/pages/session/session-whiteboard/session-whiteboard.component';

export const pageRoutes: Routes = [
    {
        path: 'login',
        component: AuthenticateComponent
    },
    {
        path: 'join',
        component: JoinSessionComponent
    },
    {
        path: 'new',
        component: NewSessionComponent,
        canActivate: [UserAuthenticatedGuard]
    },
    {
        path: 'palette',
        component: PaletteComponent
    },
    {
        path: '',
        component: JoinSessionComponent,
    },
    {
        path: 'dashboard',
        component: AuthenticatedComponent,
        canActivate: [UserAuthenticatedGuard],
        children: [
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'session',
        component: AuthenticatedComponent,
        canActivate: [UserAuthenticatedGuard],
        children: [
            {
                path: ':sessionId',
                canActivate: [EnsureSessionGuard],
                children: [
                    {
                        path: 'owner',
                        component: SessionOwnerComponent,
                        canActivate: [EnsureSessionOwnerGuard]
                    },
                    {
                        path: 'whiteboard',
                        component: SessionWhiteboardComponent,
                    },
                    {
                        path: 'participant',
                        component: SessionParticipantComponent,
                    },
                    {
                        path: 'present',
                        component: SessionPresentComponent,
                    },
                    {
                        path: 'points',
                        component: SessionPointsComponent,
                    }
                ]
            }
        ]
    }
];
