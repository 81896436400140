import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewContainerService {

  viewContainer: ViewContainerRef;
  constructor() { }

  setViewContainer(viewContainer: ViewContainerRef) {
    this.viewContainer = viewContainer;
  }

  clearViewContainer() {
    this.viewContainer = null;
  }
}
