import { Injectable } from '@angular/core';
import { IPresenter } from '@contracts/models/IPresenter';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: DataStoreModule
})
export class PresenterService extends EntityCollectionServiceBase<IPresenter> {

  constructor(elementsFactory: EntityCollectionServiceElementsFactory) {
    super('Presenter', elementsFactory);

  }
  getPresenter(): Observable<IPresenter> {
    return this.entities$.pipe(map(entities => entities && entities[0]));
  }

}
