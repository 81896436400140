import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ISession, SessionMode } from '@contracts/models/ISession';
import { IUser } from '@contracts/models/IUser';
import { LocalStorageKey } from '@web/app/core/local-storage-key.enum';
import { LocalStorageService } from '@web/app/core/local-storage.service';
import { GuideService } from '@web/app/directives/guides/guide.directive';
import { AuthService } from '@web/app/services/auth/auth.service';
import { ParticipantService } from '@web/app/store/participant.service';
import { SessionService } from '@web/app/store/session.service';
import { sortBy } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-join-session',
    templateUrl: './join-session.component.html',
    styleUrls: ['./join-session.component.scss']
})
export class JoinSessionComponent implements OnInit {
    name: string;
    sessionNumber: string;
    isBusy: boolean;
    constructor(
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private httpClient: HttpClient,
        private participantService: ParticipantService,
        private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private guideService: GuideService,
        private sessionService: SessionService
    ) {}

    public get prefilledSessionNumber() {
        const { sn } = this.activatedRoute.snapshot.queryParams;
        return sn;
    }
    public runningSessions$ = this.sessionService.entities$.pipe(
        filter(sessions => sessions.length > 0),
      map(sessions => [sortBy(sessions, 'createdAt', 'desc')[0]]),

    );

    ngOnInit() {
        this.sessionService.getWithQuery({ running: 'true' });

        this.authService.getCurrentUser().subscribe(user => {
            this.name = user?.name;
            if (this.prefilledSessionNumber) {
                this.sessionNumber = this.prefilledSessionNumber;
            }

            if (this.name && this.sessionNumber) {
                this.join(null);
            }
        });
    }

    public linkForSession(session: ISession) {
        const sessionState = {
            [SessionMode.notes]: 'present',
            [SessionMode.points]: 'points',
            [SessionMode.revealPoints]: 'points'
        };
        return ['/', 'session', session.id, sessionState[session.mode] ?? 'participant'];
    }

    join(event) {
        event?.preventDefault();
        if (!this.name) {
            this.snackBar.open('Name is required!', 'Oops!', { duration: 1500 });
            return;
        }
        if (!this.sessionNumber && !this.prefilledSessionNumber) {
            this.snackBar.open('Sessionnumber is required!', 'Oops!', { duration: 1500 });
            return;
        }
        this.isBusy = true;

        this.httpClient
            .post<IUser>('/api/v1/user', {
                name: this.name
            })
            .pipe(
                tap(user => this.localStorageService.addOrUpdateString(LocalStorageKey.accessToken, user.access_token)),
                switchMap(user => this.participantService.join(this.sessionNumber || this.prefilledSessionNumber)),
                catchError(() => of(null))
            )
            .subscribe(part => (part ? this.router.navigate(['/', 'session', part.session.id, 'participant']) : (this.isBusy = false)));
    }
}

