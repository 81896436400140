<div class="group">
    <div class="group-content"
         [class.expanded]="expanded">

        <ng-container *ngIf="canSelectGroups; else showExpander">
            <app-checkbox [checked]="selected" (click)="selectGroup.emit([group, !selected])"></app-checkbox>
        </ng-container>
        <ng-template #showExpander>
            <button mat-icon-button
                    (click)="toggle()">
                <mat-icon>{{ !expanded ? 'expand_more' : 'expand_less' }}</mat-icon>
            </button>
        </ng-template>
        <span class="group-name">
            <span> {{ group.name }}</span>
            <span *ngIf="displayPoints"
                  class="points"
                  [@scaleAndFade]>{{ points.length}} points</span>
        </span>
        <span *ngIf="!displayPoints">
            <button mat-icon-button
                    class="add-to-group-button"
                    title="Add selected notes to this group"
                    *ngIf="hasSelectedNotes; else numberOfNotes"
                    (click)="addToThis.emit(group)">
                <mat-icon>playlist_add</mat-icon>
            </button>
            <ng-template #numberOfNotes>
                <div class="number-of-notes-container">
                    <span class="number-of-notes">
                        {{ notes.length }}
                    </span>
                </div>
            </ng-template>
        </span>

    </div>
    <div class="group-items"
         *ngIf="expanded && !canSelectGroups">
        <div class="group-items__item"
             *ngFor="let note of notes">

            <app-presented-note [note]="note"
                                [note]="note">

            </app-presented-note>
            <button mat-icon-button
                    *ngIf="!displayPoints"
                    title="Remove this note from the group"
                    color="warn"
                    (click)="onRemoveNote(note)">
                <mat-icon>remove_circle</mat-icon>
            </button>
        </div>
    </div>
</div>