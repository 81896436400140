<div class="session-whiteboard-present">
    <div class="session-whiteboard_section">
        <div class="presented-notes" *ngIf="!!positive?.length" class="post-it-list positive-notes" @list>
            <app-post-it
                [@items]="presentedNote?.id !== note?.id"
                *ngFor="let note of positive; trackBy: noteFn"
                [class.is-presenting]="presentedNote?.id === note?.id"
                [class.medium-text]="note.text.length > 50"
                [class.long-text]="note.text.length > 80"
                #positive
                [rotateDeg]="note.rotation"
                class="small view"
            >
                {{ note.text }}
            </app-post-it>
        </div>
    </div>
    <div class="session-whiteboard_section">
        <div *ngIf="!!negative.length" class="post-it-list" @list>
            <app-post-it
                [@items]="presentedNote?.id !== note?.id"
                *ngFor="let note of negative; trackBy: noteFn"
                theme="dark"
                [class.is-presenting]="presentedNote === note"
                [class.medium-text]="note.text.length > 50"
                [class.long-text]="note.text.length > 80"
                [rotateDeg]="note.rotation"
                #negative
                class="small view"
            >
                {{ note.text }}
            </app-post-it>
        </div>
    </div>
    <div class="presented-note-overlay" [class.active-overlay]="!!presentedNote"></div>
    <div *ngIf="presentedNote && hasEndposition" [@presentedNote]="{ value: ':leave', params: endPosition }" class="small view presented-note">
        <app-post-it [rotate]="false" [class.long-text]="presentedNote.text.length > 80" [class.medium-text]="presentedNote.text.length > 50" [theme]="presentedNote.kind === NoteKind.positive ? 'light' : 'dark'">
            {{ presentedNote.text }}
        </app-post-it>
    </div>
</div>

But I have so much to say, that the length of this postit will far
I've got stuff to say as well, and I'd like to say them!
