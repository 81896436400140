<div class="action-point"
     (click)="select.emit(action)">
    <div class="controls"
         *ngIf="!isActive">
        <button mat-flat-button color="warn"
                (click)="delete(action, $event)">Delete</button>
    </div>
    <div class="title"
         *ngIf="isActive; else readonly"
         contenteditable="true"
         [ngModel]="title"
         #actionTitle
         (blur)="reattach()"
         (focus)="detachChange()"
         (keydown)="keyDown($event)"
         (ngModelChange)="valueChange($event)"
         [class.is--empty]="!title">

    </div>
    <ng-template #readonly>
        <div class="action-point--readonly"><span class="bullet"><mat-icon>keyboard_arrow_right</mat-icon></span>{{ action.title }} <span
                  *ngIf="action.assignee">({{ action.assignee }})</span></div>
    </ng-template>
</div>