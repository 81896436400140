import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CheckboxModule } from '@web/app/components/checkbox/checkbox.module';
import { PresentedNoteModule } from './presented-note/presented-note.module';
import { PresentedNoteListComponent } from './presented-notes-list.component';



@NgModule({
    declarations: [PresentedNoteListComponent],
    exports: [PresentedNoteListComponent],
    imports: [
        CommonModule,
        MatIconModule,
        DragDropModule,
        CheckboxModule,
        PresentedNoteModule
    ]
})
export class PresentedNotesListModule { }
