<div class="participants-list"
     [class.hasPresenter]="currentPresenter"
     [class.no-participants]="participants.length === 0"
     @list>
    <ng-container *ngIf="participants.length > 0; else noParticipants">
        <div (click)="onTogglePresenter(participant)"
             class="participants-list__item"
             @items
             [class.collapsed]="collapsed"
             [class.ready-to-present]="participant.isDone"
             [class.highlighted]="currentPresenter && participant.user.id === currentPresenter.user.id"
             *ngFor="let participant of participants; trackBy: participantTrackByFn">
            <div class="user-info">
                <div class="avatar-container">
                    <div class="avatar"
                         [ngClass]="participant.status">
                        {{ getShortName(participant.user.name) }}
                    </div>
                </div>
                <div class="name">
                    <div>
                        {{ participant.user.name }}
                    </div>
                    <div class="user-status">
                        <div *ngIf="isCurrentlyWriting(participant)">
                            Writing...
                        </div>
                        <div *ngIf="isCurrentlyPresenting(participant)">
                            Presenting...
                        </div>
                        <div *ngIf="isDoneGivingPoints(participant)">
                            Done
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
    <ng-template #noParticipants>
        <div class="no-participants-info"
             *ngIf="!collapsed">
            <div class="no-participants-info__title">
                Nobody here yet
            </div>
            <div class="no-participants-info__subtext">
                Waiting for someone to join this session
            </div>
        </div>
    </ng-template>
</div>