import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUser } from '@contracts/models/IUser';
import { LocalStorageKey } from '@web/app/core/local-storage-key.enum';
import { LocalStorageService } from '@web/app/core/local-storage.service';

@Component({
    selector: 'app-authenticate',
    templateUrl: './authenticate.component.html',
    styleUrls: ['./authenticate.component.scss'],
    animations: [
        trigger('control', [
            // cubic-bezier for a tiny bouncing feel
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(0.5)', opacity: 0, height: '0px' }))
            ]),
        ])
    ]
})
export class AuthenticateComponent implements OnInit {

    @Input() mode: 'signin' | 'signup' = 'signin';

    name: string;
    email: string;
    password: string;
    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService) { }

    ngOnInit() {

    }
    submitAuthentication($event) {
        if (this.mode === 'signin') {
            this.login($event);
        } else {
            this.signUp($event);
        }
    }

    signUp($event) {
        $event.preventDefault();
        if (this.mode === 'signin') {
            this.mode = 'signup';
            return;
        } else {
            this.mode = 'signin';
            return;
        }
    }

    login($event) {
        $event.preventDefault();
        const redirect = this.route.snapshot.queryParamMap.get('redirectUrl') || '/dashboard';
        if (this.mode === 'signup') {
            this.mode = 'signin';
            this.httpClient.post<IUser>('/api/v1/user', {
                email: this.email,
                password: this.password,
                name: this.name
            }).subscribe(res => {
                this.localStorageService.addOrUpdateString(LocalStorageKey.accessToken, res.access_token);
                window.location.href = redirect;
            });
            return;
        }

        if (this.password && this.email) {
            this.httpClient.post<IUser>(`/api/v1/user/${this.email}`, {
                password: this.password
            }).subscribe(res => {
                // debugger;
                // this.localStorageService.addOrUpdateString(LocalStorageKey.accessToken, res.access_token);
                window.location.href = redirect;
            });
        }
    }

    authenticate() {

    }
}
