import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { IGroup } from '@contracts/models/IGroup';
import { ContentEditableChangeEventArgs } from '@web/app/directives/content-editable-form.directive';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-group-discussion',
    templateUrl: './group-discussion.component.html',
    styleUrls: ['./group-discussion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupDiscussionComponent implements OnInit, OnDestroy {
    @Input() public group: IGroup;
    @Input() public actionPoints: IActionPoint[] = [];
    @Input() public activeActionPoint: IActionPoint;

    @Output() public selectActionPoint = new EventEmitter<IActionPoint>();
    @Output() public descriptionChange = new EventEmitter<[IGroup, string]>();
    @Output() public addActionPoint = new EventEmitter<[IGroup, IActionPoint]>();
    @Output() public saveActionPoint = new EventEmitter<IActionPoint>();
    description: { text: string, html: string };
    newAction: IActionPoint;
    expanded: boolean = true;
    debouncer: Subject<[IGroup, string]> = new Subject<[IGroup, string]>();
    destroyed$ = new Subject<void>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.debouncer.pipe(
            takeUntil(this.destroyed$),
            debounceTime(500)
        ).subscribe((value) => this.descriptionChange.emit(value));
    }

    isActive(actionPoint: IActionPoint) {
        return actionPoint.id === this.activeActionPoint?.id;
    }

    actionPointTrackBy(_, actionPoint: IActionPoint) {
        return actionPoint.id;
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    detachChange() {
        this.changeDetectorRef.detach();
    }

    reattach() {
        this.changeDetectorRef.reattach();
        this.debouncer.next([this.group, this.description.html]);
    }

    ngOnInit(): void {
        this.description = {
            text: this.group?.description ?? '',
            html: this.group?.description ?? ''
        };
    }

    createNew() {
        this.createActionPoint();
    }

    createActionPoint() {
        this.addActionPoint.emit([this.group, <any>{}]);
    }

    update(action: IActionPoint) {
        this.saveActionPoint.emit(action);
    }

    contentChanged(event: ContentEditableChangeEventArgs) {
        this.description = event;
        this.debouncer.next([this.group, this.description.html]);
    }
}
