import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: DataStoreModule
})
export class ActionPointService extends EntityCollectionServiceBase<IActionPoint> {

  constructor(private http: HttpClient, elementsFactory: EntityCollectionServiceElementsFactory) {
    super('ActionPoint', elementsFactory);

  }


}
