<div class="session-participant" [class.session-participant_note--adding]="newNote">
    <ng-container *ngIf="(notes$ | async).length; else noNotes">
        <app-note-list [notes]="notes$ | async" [viewOnly]="true" [selectedNote]="selectedNote" (selectNote)="onSelectNote($event)"></app-note-list>
    </ng-container>
    <ng-template #noNotes>
        <div class="instructions">
            <p class="title">Nothing here yet</p>
            <p> Start by adding positive or negative feedback by clicking the <mat-icon>add</mat-icon> button in the bottom menu.</p>
            <p class="subtext">When you're done, click "Done" to get to the next step!</p>
        </div>
    </ng-template>
    <app-page-actions *ngIf="!newNote">
        <button id="thumbDownButton" mat-mini-fab (click)="createNote()"  color="primary" class="with-content"> New note <mat-icon aria-label="">add</mat-icon> </button>

        <button id="doneButton" class="with-content"  color="primary" mat-mini-fab (click)="done()"> Done <mat-icon>arrow_right_alt</mat-icon> </button>
    </app-page-actions>

    <app-page-actions *ngIf="newNote">
        <button mat-mini-fab class="with-content"  color="primary" (click)="deleteNote()" *ngIf="newNote.id"> Delete <mat-icon aria-label="">delete</mat-icon> </button>
        <button mat-mini-fab class="with-content"  color="primary" (click)="dismissNewNote()"> Cancel <mat-icon aria-label="">close</mat-icon> </button>
    </app-page-actions>

    <div class="session-participant_note--adding_overlay" (click)="dismissNewNote()"></div>
    <div class="session-participant_note" @addNote *ngIf="newNote">
        <app-post-it class="note" size="stretch" [theme]="newNote && newNote.kind === NoteKind.positive ? 'light' : 'dark'" [rotate]="false">
            <div class="postit-form">
                <form (submit)="save($event)" class="note-form">
                    <textarea
                        matInput
                        #noteText
                        autocomplete="false"
                        autofocus
                        name="note-text"
                        rows="6"
                        appInputBox
                        [(ngModel)]="newNote.text"
                        placeholder="Your text goes here"
                    >
 {{ newNote.text }}</textarea
                    >
                </form>
                <div class="buttons" [ngClass]="newNote.kind">
                    <button id="thumbUpButton" class="with-content" color="primary" mat-mini-fab (click)="changeKind(NoteKind.positive)" *ngIf="newNote.kind === NoteKind.negative">
                        Switch to positive <mat-icon aria-label="">thumb_up</mat-icon>
                    </button>

                    <button id="thumbDownButton" class="with-content" color="primary" mat-mini-fab (click)="changeKind(NoteKind.negative)" *ngIf="newNote.kind === NoteKind.positive">
                        Switch to negative <mat-icon aria-label="">thumb_down</mat-icon>
                    </button>
                    <div>
                        <button mat-mini-fab class="with-content bg-primary-600" color="primary" (click)="save($event)">
                            Save <mat-icon aria-label="">save</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </app-post-it>
    </div>
</div>

