import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ContenteditableDirective } from '@web/app/directives/content-editable-form.directive';
import { GuideDirective, GuideInstructionComponent } from './guides/guide.directive';
import { InputBoxDirective } from './input-box.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [ContenteditableDirective, InputBoxDirective, GuideDirective, GuideInstructionComponent],
  exports: [ContenteditableDirective, InputBoxDirective, GuideDirective]
})
export class DirectivesModule { }
