import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISession } from '@contracts/models/ISession';
import { SessionService } from '@web/app/store/session.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    sessions$: Observable<ISession[]>;

    constructor(private router: Router, private sessionService: SessionService) { }

    ngOnInit() {
        this.sessions$ = this.sessionService.entities$;

    }


    createSession() {
        this.router.navigate(['/', 'new']);
    }
}
