<ng-container *ngIf="currentSession$ | async as currentSession; else spinner">

    <div class="navigation">
        <div class="actions">
            <div class="previous">
                <button mat-raised-button
                        *ngIf="previousStep(currentSession.mode)"
                        (click)="toggleMode(currentSession, previousStep(currentSession.mode))">
                    <mat-icon>keyboard_arrow_left</mat-icon>{{ previousStepName(currentSession.mode) }}
                </button>&nbsp;
            </div>
            <div  class="current-step">
                <div>{{ currentStepName(currentSession.mode) }}</div>
            </div>
            <div class="next">&nbsp;
                <button mat-raised-button
                        *ngIf="nextStep(currentSession.mode)"
                        color="accent"
                        (click)="toggleMode(currentSession, nextStep(currentSession.mode))">
                    {{ nextStepName(currentSession.mode) }}<mat-icon>keyboard_arrow_right</mat-icon>
                </button>

            </div>
        </div>
    </div>
    <div class="session"
         [class.discuss]="currentSession.mode === SessionMode.discussion"
         [class.participants-collapsed]="participantCollapsed || currentSession.mode === SessionMode.discussion">
        <div class="session-owner_sidebar">
            <app-participants [collapsed]="participantCollapsed || currentSession.mode === SessionMode.discussion"
                              [participants]="participants$ | async"
                              [currentPresenter]="currentPresenter$ | async"
                              (removePresenter)="removePresenter($event)"
                              (setPresenter)="setPresenter($event)"></app-participants>
            <div class="collapse">
                <button mat-icon-button
                        (click)="participantCollapsed = !participantCollapsed">
                    <mat-icon>{{ 'keyboard_arrow_left' }}</mat-icon>
                </button>
            </div>
        </div>

        <div class="session-owner_notes">
            <app-discuss *ngIf="currentSession.mode === SessionMode.discussion; else showNotes"
                         (descriptionChange)="onGroupDescriptionChange($event)"
                         (addActionPoint)="onCreateActionPoint($event)"
                         (saveActionPoint)="onSaveActionPoint($event)"
                         (selectActionPoint)="setActiveActionPoint($event)"
                         [actionPoints]="actionPoints$ | async"
                         [session]="currentSession"
                         [activeActionPoint]="activeActionPoint$ | async"
                         [groups]="groups$ | async"
                         [@scaleAndFadeIn]></app-discuss>
            <ng-template #showNotes>
                <app-presented-note-list *ngIf="notes$ | async as notes"
                                         [@scaleAndFadeIn]
                                         [groupLists]="groupLists$ | async"
                                         [kind]="displayedNoteKind$ | async"
                                         [notes]="notes"
                                         [selectedNotes]="selectedNotes"
                                         (select)="onSelectNote($event)"
                                         (delete)="removeFromPresentedNotes($event)"></app-presented-note-list>
            </ng-template>
        </div>

        <div class="session-owner_groups">
            <app-note-groups [groups]="groups$ | async"
                             [points]="points$ | async"
                             [selectedNotes]="selectedNotes"
                             [groupedNotes]="groupedNotes$ | async"
                             [session]="currentSession"
                             (selectGroup)="onSelectGroup($event)"
                             (addToGroup)="onAddToGroup($event)"
                             (remove)="onRemoveNote($event)"
                             (createGroup)="onCreateGroup($event)">

            </app-note-groups>

        </div>
    </div>
</ng-container>

<ng-template #spinner>
    <app-spinner></app-spinner>
</ng-template>