import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { IGroup } from '@contracts/models/IGroup';

@Component({
    selector: 'app-group-summary',
    templateUrl: './group-summary.component.html',
    styleUrls: ['./group-summary.component.scss']
})
export class GroupSummaryComponent implements OnInit {
    @Input() public group: IGroup;
    @Input() public actionPoints: IActionPoint[];

    constructor(public domSanitize: DomSanitizer) {
    }


    public get title() {
        return this.group.name;
    }
    public get description() {
        return this.domSanitize.bypassSecurityTrustHtml(this.group.description ?? '');
    }


    actionPointTrackFn(_, actionPoint: IActionPoint)  {
        return actionPoint.id;
    }
    ngOnInit(): void {
    }

}
