import { ActionReducerMap, createSelector } from '@ngrx/store';
import { ILayoutSlice } from '@web/app/store/app/layout/layout.reducer';
import * as fromLayout from './layout/layout.reducer';


export interface AppState {
    layout: ILayoutSlice;
}

export const reducers: ActionReducerMap<AppState> = {
    layout: fromLayout.layoutReducer
};

export const getLayoutSlice = (state: AppState) => state.layout;

export const getIsSnackbarOpen = createSelector(getLayoutSlice, fromLayout.getSnackbarOpen);
