import { animate, animateChild, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { IGroup } from '@contracts/models/IGroup';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { INote, NoteKind } from '@contracts/models/INote';
import { ISession, SessionMode } from '@contracts/models/ISession';
import { IUser } from '@contracts/models/IUser';
import { NotePresented, SocketActionTypes } from '@contracts/socket/socket-action';
import { HeaderService } from '@web/app/layout/header/header.service';
import { SocketIoService } from '@web/app/services/socket/socket-io.service';
import { ActionPointService } from '@web/app/store/action-points.service';
import { GroupPointService } from '@web/app/store/group-point.service';
import { GroupService } from '@web/app/store/group.service';
import { GroupedNoteService } from '@web/app/store/grouped-note.service';
import { NoteService } from '@web/app/store/me.service';
import { PresenterService } from '@web/app/store/presenter.service';
import { SessionService } from '@web/app/store/session.service';
import { environment } from '@web/environments/environment';
import { orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-session-whiteboard',
    templateUrl: './session-whiteboard.component.html',
    styleUrls: ['./session-whiteboard.component.scss'],
    animations: [
        trigger('presenter', [
            transition(':enter', [
                style({ transform: 'scale(4) translateY(-15vh)' }),
              animate('.5s 2s cubic-bezier(.79,-0.3,.51,.86)', keyframes(
                [
                  style({ transform: 'scale(0.7)', offset: 0.5 }),
                  style({ transform: 'scale(1)', offset: 1 })
                ]))
            ])
        ]),
        trigger('fade', [transition(':leave', [style({ opacity: 1 }), animate('0.3s ease-in', style({ opacity: 0, transform: 'scale(0.4)' }))])]),
        trigger('modes', [transition(':enter', [query('@mode', stagger(50, animateChild()), { optional: true })])]),
        trigger('mode', [
            transition(':enter', [style({ opacity: 0 }), animate('1s cubic-bezier(.8,-0.6,0.2,1.5)', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('1s cubic-bezier(.8,-0.6,0.2,1.5)', style({ opacity: 0 }))])
        ])
    ]
})
export class SessionWhiteboardComponent implements OnInit {
    isInitialLoad = true;
    currentSession$: Observable<ISession>;
    public NoteKind = NoteKind;
    public SessionMode = SessionMode;
    notes$: Observable<INote[]>;
    positiveNotes$: Observable<(INote & { rotation: number })[]>;
    negativeNotes$: Observable<(INote & { rotation: number })[]>;
    groups$: Observable<IGroup[]>;
    groupPoints$: Observable<IGroupPoint[]>;
    groupedNotes$: Observable<IGroupedNote[]>;
    showSplash$: Observable<boolean>;
    lastPresentedNote$: Observable<any>;
    currentPersenter$: Observable<IUser>;
    actionPoints$: Observable<IActionPoint[]>;
    presentedNote$ = new BehaviorSubject<(INote & { rotation: number })>(null);

    private pendingNote: INote;

    private rotations = {};

    private getRotation(note: INote) {
        if (!this.rotations[note.id]) {
            this.rotations[note.id] = Math.random() * (7 * 2) - 7;
        }
        return this.rotations[note.id];
    }

    constructor(
        sessionService: SessionService,
        groupService: GroupService,
        groupPointService: GroupPointService,
        groupedNoteService: GroupedNoteService,
        headerService: HeaderService,
        presenterService: PresenterService,
        actionPointsService: ActionPointService,
        private noteService: NoteService,
        socket: SocketIoService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.actionPoints$ = actionPointsService.entities$;
        this.groups$ = groupService.entities$;
        this.groupPoints$ = groupPointService.entities$;
        this.groupedNotes$ = groupedNoteService.entities$;
        this.currentSession$ = sessionService.getCurrentSession();
        this.currentSession$
            .pipe(
                filter(s => !!s),
                map(session => `Join session: ${session.sessionNumber}`)
            )
            .subscribe(text => headerService.setHeader(text, { hidden: true }));
        this.notes$ = noteService.entities$.pipe(
            map(n =>
                orderBy(
                    n.filter(note => note.presented),
                    'timeUpdated',
                    ['asc']
                )
            )
        );
        this.positiveNotes$ = this.notes$.pipe(
            map(notes => notes.filter(note => note.kind === NoteKind.positive).map(note => ({ ...note, rotation: this.getRotation(note) })))
        );
        this.negativeNotes$ = this.notes$.pipe(
            map(notes => notes.filter(note => note.kind === NoteKind.negative).map(note => ({ ...note, rotation: this.getRotation(note) })))
        );

        this.currentPersenter$ = presenterService.getPresenter().pipe(map(presenter => presenter?.user));

        this.showSplash$ = combineLatest([this.notes$, this.presentedNote$, this.currentPersenter$]).pipe(
            map(([notes, presentedNote, presenter]) => !presenter && !presentedNote && notes.filter(n => n.presented).length === 0)
        );

        socket
            .fromEvent(SocketActionTypes.NotePresented)
            .pipe(
                map((action: NotePresented) => ({
                    ...action.note,
                    rotation: this.getRotation(action.note)
                }))
            )
            .subscribe(this.presentNote);
    }
    ngOnInit() {
        this.isInitialLoad = false;
    }

    presentNote = (note: (INote & { rotation: number })) => {
        this.presentedNote$.next(note);
        let addNoteTimeout = null;
        if (addNoteTimeout) {
            clearTimeout(addNoteTimeout);
            this.addNoteToCollection(this.pendingNote);
            addNoteTimeout = null;
        }
        this.pendingNote = note;
        addNoteTimeout = setTimeout(() => {
            this.addNoteToCollection(note);
            addNoteTimeout = null;
        }, environment.appSettings.notePresentationTime);
    };

    addNoteToCollection = (note: INote) => {
        this.noteService.getByKey(note.id).subscribe(() => {
            this.presentedNote$.next(null);
        });
    };
}

