import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGroup } from '@contracts/models/IGroup';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: DataStoreModule
})
export class GroupService extends EntityCollectionServiceBase<IGroup> {

  constructor(private http: HttpClient, elementsFactory: EntityCollectionServiceElementsFactory) {
    super('Group', elementsFactory);

  }


}
