<div class="discuss"
     [class.intro]="selectedGroups.length === 0">
    <div *ngIf="selectedGroups.length > 0; else intro" class="discuss-groups">
        <app-group-discussion *ngFor="let group of selectedGroups; trackBy: groupTrackBy"
                            [actionPoints]="getActionPointsForGroup(group)"
                            [activeActionPoint]="activeActionPoint"
                            (selectActionPoint)="selectActionPoint.emit($event)"
                            (descriptionChange)="descriptionChange.emit($event)"
                            (addActionPoint)="onAddActionPoint($event)"
                            (saveActionPoint)="saveActionPoint.emit($event)"
                              [group]="group"></app-group-discussion>
    </div>
    <ng-template #intro>
        <div class="discuss-intro">
            <div class="discuss-intro__title">
                Time to discuss
            </div>
            <div class="discuss-intro__subtext">
                Start by selecting the groups you want to focus on from the list on the right
            </div>
        </div>
    </ng-template>
</div>