<div class="session-whiteboard"
     *ngIf="currentSession$ | async as currentSession"
     @modes>
    <ng-container *ngIf="!(showSplash$ | async); else sessionNumber">
        <app-present-notes @mode
                           *ngIf="currentSession.mode === SessionMode.notes"
                           [positiveNotes]="positiveNotes$ | async"
                           [negativeNotes]="negativeNotes$ | async"
                           [currentSession]="currentSession"
                           [presentedNote]="presentedNote$ | async"></app-present-notes>
        <app-gruop-display @mode
                           *ngIf="currentSession.mode === SessionMode.points || currentSession.mode === SessionMode.revealPoints"
                           [groups]="groups$ | async"
                           [sessionMode]="currentSession.mode"
                           [groupedNotes]="groupedNotes$ | async"
                           [groupPoints]="groupPoints$ | async">

        </app-gruop-display>
        <app-session-summary *ngIf="currentSession.mode === SessionMode.discussion"
            [groups]="groups$ | async"
            [actionPoints]="actionPoints$ | async"
        >

        </app-session-summary>
    </ng-container>
    <ng-template #sessionNumber>
        <div class="session-number" @fade>
            <div class="title">Session <strong>{{currentSession.sessionNumber }}</strong></div>
            <app-session-qrcode [sessionNumber]="currentSession.sessionNumber"></app-session-qrcode>
        </div>
    </ng-template>
    <ng-container *ngIf="currentSession.mode === SessionMode.notes">
        <div class="presenter"
             *ngIf="currentPersenter$ | async as currentPresenter">
            <div class="title"  @presenter> {{ currentPresenter.name }} presenting
            </div>

        </div>
    </ng-container>
</div>
