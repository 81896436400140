import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionSummaryComponent } from './session-summary.component';
import { GroupSummaryComponent } from './group-summary/group-summary.component';



@NgModule({
  declarations: [SessionSummaryComponent, GroupSummaryComponent],
  imports: [
    CommonModule
  ],
  exports: [SessionSummaryComponent]
})
export class SessionSummaryModule { }
