import { Injectable } from '@angular/core';
import { ISession } from '@contracts/models/ISession';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: DataStoreModule
})
export class SessionService extends EntityCollectionServiceBase<ISession> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory) {
    super('Session', elementsFactory);
  }

  public getCurrentSession() {
    return this.filteredEntities$.pipe(
      map(e => e.length === 1 ? e[0] : null)
    );
  }

  setSelectedSessionId(id: string) {
    this.setFilter(id);
  }
}
