import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISession } from '@contracts/models/ISession';
import { IUser } from '@contracts/models/IUser';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() currentSession: ISession;
  @Input() currentUser: IUser;
  @Output() logout = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
  }

  public get isSessionOwner() {
      return this.currentSession.owner.id === this.currentUser.id;
  }
}
