import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NoteListComponent } from './note-list.component';



@NgModule({
    declarations: [NoteListComponent],
    exports: [NoteListComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatDividerModule
    ]
})
export class NoteListModule { }
