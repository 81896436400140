import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { InstructionModule } from '@web/app/components/instruction/instruction.module';
import { PageActionsModule } from '@web/app/components/page-actions/page-actions.module';
import { PostItModule } from '@web/app/components/post-it/post-it.module';
import { SpinnerModule } from '@web/app/components/spinner/spinner.module';
import { DirectivesModule } from '@web/app/directives/directives.module';
import { LayoutModule } from '@web/app/layout/layout.module';
import { pageRoutes } from '@web/app/pages/pages.routes';
import { PipesModule } from '@web/app/pipes/pipes.module';
import { AuthenticateModule } from './authenticate/authenticate.module';
import { AuthenticatedComponent } from './authenticated.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SessionListComponent } from './dashboard/session-list/session-list.component';
import { PaletteComponent } from './palette/palette.component';
import { JoinSessionComponent } from './session-start/join-session/join-session.component';
import { NewSessionComponent } from './session-start/new-session/new-session.component';
import { SessionStartComponent } from './session-start/session-start.component';
import { DiscussModule } from './session/session-owner/discuss/discuss.module';
import { GroupComponent } from './session/session-owner/group/group.component';
import { NewGroupComponent } from './session/session-owner/new-group/new-group.component';
import { NoteGroupsModule } from './session/session-owner/note-groups/note-groups.module';
import { ParticipantsModule } from './session/session-owner/participants/participants.module';
import { PresentedNotesListModule } from './session/session-owner/presented-note-list/presented-notes-list.module';
import { SessionOwnerComponent } from './session/session-owner/session-owner.component';
import { NoteComponent } from './session/session-participant/note/note.component';
import { SessionParticipantModule } from './session/session-participant/session-participant.module';
import { SessionPointsGroupListComponent } from './session/session-points/session-points-group-list/session-points-group-list.component';
import { SessionPointsComponent } from './session/session-points/session-points.component';
import { SessionPresentModule } from './session/session-present/session-present.module';
import { GruopDisplayComponent } from './session/session-whiteboard/gruop-display/gruop-display.component';
import { PresentNotesComponent } from './session/session-whiteboard/present-notes/present-notes.component';
import { SessionQRCodeModule } from './session/session-whiteboard/session-qrcode/session-qrcode.module';
import { SessionSummaryModule } from './session/session-whiteboard/session-summary/session-summary.module';
import { SessionWhiteboardComponent } from './session/session-whiteboard/session-whiteboard.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LayoutModule,
        ReactiveFormsModule,
        RouterModule.forChild(pageRoutes),
        SpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatCardModule,
        MatListModule,
        MatButtonModule,
        MatSnackBarModule,
        PostItModule,
        DragDropModule,
        DirectivesModule,
        PageActionsModule,
        PipesModule,
        InstructionModule,
        SessionParticipantModule,
        SessionPresentModule,
        ParticipantsModule,
        PresentedNotesListModule,
        AuthenticateModule,
        NoteGroupsModule,
        SessionQRCodeModule,
        DiscussModule,
        SessionSummaryModule
    ],
    exports: [RouterModule],
    declarations: [
        SessionStartComponent,
        JoinSessionComponent,
        SessionOwnerComponent,
        SessionListComponent,
        NoteComponent,
        SessionPointsComponent,
        SessionWhiteboardComponent,
        GroupComponent,
        NewGroupComponent,
        SessionPointsComponent,
        SessionPointsGroupListComponent,
        AuthenticatedComponent,
        PresentNotesComponent,
        GruopDisplayComponent,
        NewSessionComponent,
        DashboardComponent,
        PaletteComponent]
})
export class PagesModule { }
