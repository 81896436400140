<div>
    <div class="header">
        {{ title }}
    </div>
    <div class="body"
         [innerHTML]="description">

    </div>
    <div class="actionPoints">
        <ul>
            <li *ngFor="let actionPoint of actionPoints; trackBy: actionPointTrackFn"> {{ actionPoint.title }}</li>
        </ul>
    </div>
</div>