import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DefaultDataServiceConfig, EntityMetadataMap, PropsFilterFnFactory, EntityDataModule } from '@ngrx/data';

export const entityMetadata: EntityMetadataMap = {
    Session: {
        filterFn: idFilter
    },
    Note: {},
    Participant: {
        filterFn: idFilter
    },
    Presenter: {},
    Group: {},
    GroupedNote: {},
    GroupPoint: {},
    ActionPoint: {}
};

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: 'api/v1', // default root path to the server's web api
};


/** Filter for entities whose name matches the case-insensitive pattern */
export function idFilter<T extends { id: string }>(entities: T[], pattern: string) {
    return PropsFilterFnFactory(['id'])(entities, pattern);
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        EntityDataModule.forRoot({
            entityMetadata: entityMetadata
        })
    ],
    providers: [
        { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig }]
})
export class DataStoreModule { }
