import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-action-point',
    templateUrl: './action-point.component.html',
    styleUrls: ['./action-point.component.scss']
})
export class ActionPointComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() public action: IActionPoint;
    @Input() public isActive: boolean;
    @Output() public update = new EventEmitter<IActionPoint>();
    @Output() public select = new EventEmitter<IActionPoint>();
    @Output() public createNew = new EventEmitter();
    @ViewChild('actionTitle') public actionTitle: ElementRef;

    title: { text: string, html: string };
    debouncer: Subject<IActionPoint> = new Subject<IActionPoint>();
    destroyed$ = new Subject<void>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.debouncer.pipe(
            takeUntil(this.destroyed$),
            debounceTime(1000),
            filter(() => this.isActive)
        ).subscribe((value) => this.update.emit(value));
    }

    delete(action: IActionPoint, event: any) {
        event.preventDefault();
        this.update.emit({
            ...action,
            title: null
        });

    }
    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.isActive && simpleChanges.isActive.currentValue) {
            this.autofocus();
        }
    }

    ngAfterViewInit() {
        this.autofocus();
    }

    autofocus() {
        if (this.isActive) {
            setTimeout(() => {
                console.log('focus', this.actionTitle.nativeElement);
                this.actionTitle.nativeElement.focus();
            }, 150);
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    detachChange() {
        this.changeDetectorRef.detach();
    }

    reattach() {
        this.changeDetectorRef.reattach();
        this.update.emit({
            ...this.action,
            title: this.title.text
        });
        this.select.emit(null);
    }


    ngOnInit() {
        this.title = {
            text: this.action.title || '',
            html: this.action.title || '',
        }
    }

    keyDown($event) {
        const keyCode = $event.keyCode || $event.which;

        if (keyCode === 13) {
            this.changeDetectorRef.reattach();

            this.update.emit({
                ...this.action,
                title: this.title.text
            });
            this.select.emit(null);
            if (this.title.text) {
                this.createNew.emit();
            }
        }

    }

    valueChange($event) {
        this.title = $event;
        this.debouncer.next({
            ...this.action,
            title: this.title.text
        });

    }
}
