import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INote, NoteKind } from '@contracts/models/INote';
import { IParticipant } from '@contracts/models/IParticipant';
import { ISession } from '@contracts/models/ISession';
import { GuideService } from '@web/app/directives/guides/guide.directive';
import { HeaderService } from '@web/app/layout/header/header.service';
import { AuthService } from '@web/app/services/auth/auth.service';
import { NoteService } from '@web/app/store/me.service';
import { ParticipantService } from '@web/app/store/participant.service';
import { SessionService } from '@web/app/store/session.service';
import { combineLatest, noop, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-session-participant',
    templateUrl: './session-participant.component.html',
    styleUrls: ['./session-participant.component.scss'],
    animations: [
        trigger('addNote', [
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px' }))
            ])
        ])
    ]
})
export class SessionParticipantComponent implements OnInit {
    public NoteKind = NoteKind;

    stacked: boolean;
    currentSession$: Observable<ISession>;
    numberOfParticipants$: Observable<number>;
    notes$: Observable<INote[]>;
    selectedNote: INote;
    newNote: INote;
    currentParticipant$: Observable<IParticipant>;
    @ViewChild('noteText') noteTextElement: ElementRef;

    constructor(
        headerService: HeaderService,
        private sessionService: SessionService,
        private participantService: ParticipantService,
        private authService: AuthService,
        private noteService: NoteService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private guideService: GuideService
    ) {
        // headerService.setHeader('');
        this.numberOfParticipants$ = this.participantService.entities$.pipe(map(e => e.length));
        this.notes$ = noteService.entities$;
        this.currentSession$ = this.sessionService.getCurrentSession();
        this.currentParticipant$ = combineLatest([this.currentSession$, this.authService.getCurrentUser(), this.participantService.entities$]).pipe(
            filter(([_, user]) => !!user),
            map(([currentSession, user, participants]) => participants.find(p => p.session.id === currentSession.id && p.user.id === user.id))
        );
    }

    @HostListener('document:keyup', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.dismissNewNote();
        }
    }

    ngOnInit() {
        this.currentSession$ = this.sessionService.getCurrentSession();
        this.currentParticipant$
            .pipe(
                filter(participant => !!participant),
                take(1),
                switchMap(participant =>
                    this.participantService.update({
                        ...participant,
                        isDone: false
                    })
                )
            )
            .subscribe(() => noop);
    }

    changeKind(noteKind: NoteKind) {
        this.newNote = {
            ...this.newNote,
            kind: noteKind
        };
    }
    onSelectNote(note: INote) {
        this.newNote = note;
        setTimeout(() => {
            if (this.noteTextElement && this.noteTextElement.nativeElement) {
                this.noteTextElement.nativeElement.focus();
            }
        }, 300);
    }

    deleteNote() {
        this.noteService.delete(this.newNote);
        this.newNote = null;
        this.selectedNote = null;
    }

    private previousNoteKind: NoteKind;

    onCancelEdit() {
        this.newNote = null;
    }
    dismissNewNote() {
        this.newNote = null;
    }
    save(event: any) {
        event.preventDefault();
        this.currentSession$.pipe(take(1)).subscribe(session => {
            this.previousNoteKind = this.newNote.kind;
            this.noteService.addNote({
                ...this.newNote,
                session: session
            });
            this.newNote = null;
        });
    }

    createNote() {
        console.log(this.previousNoteKind);
        this.newNote = <INote>{
            kind: this.previousNoteKind ?? NoteKind.positive
        };
        setTimeout(() => this.noteTextElement.nativeElement.focus(), 300);
    }

    done() {
        this.currentParticipant$
            .pipe(
                take(1),
                switchMap(participant =>
                    this.participantService.update({
                        ...participant,
                        isDone: true
                    })
                )
            )
            .subscribe(participant => {
                this.router.navigate(['..', 'present'], {
                    relativeTo: this.activatedRoute
                });
            });
    }
}

