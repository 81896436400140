import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IParticipant } from '@contracts/models/IParticipant';
import { IPresenter } from '@contracts/models/IPresenter';

@Component({
    selector: 'app-participants',
    templateUrl: './participants.component.html',
    styleUrls: ['./participants.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [

        // nice stagger effect when showing existing elements
        trigger('list', [
            transition(':enter', [
                query('@items',
                    stagger(50, animateChild())
                    , { optional: true })
            ]),
        ]),
        trigger('items', [
            // cubic-bezier for a tiny bouncing feel
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('0.5s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px' }))
            ]),
        ])
    ]
})
export class ParticipantsComponent {
    @Input() participants: IParticipant[];
    @Input() currentPresenter: IPresenter;
    @Input() public collapsed: boolean;
    @Output() setPresenter = new EventEmitter<IPresenter>();
    @Output() removePresenter = new EventEmitter<IPresenter>();

    onTogglePresenter(presenter: IPresenter) {
        if (this.currentPresenter && presenter.user.id === this.currentPresenter.user.id) {
            this.removePresenter.next(this.currentPresenter);
        } else {
          this.removePresenter.next(this.currentPresenter);
          setTimeout(() => {
            this.setPresenter.next(presenter);
          }, 500);
        }
    }

    getShortName(name: string) {
        const [first, ...rest] = name.split(' ');
        const [last, ..._] = [...rest].reverse();
        return `${first[0]}${(last?.[0] ?? '')}`;
    }

    participantTrackByFn(_, participant: IParticipant) {
        return participant.id;
    }

    isCurrentlyWriting(user: IParticipant) {
        return !user.isDone;
    }

    isCurrentlyPresenting(user: IParticipant) {
        if (this.isCurrentlyWriting(user)) {
            return false;
        }

        return this.currentPresenter?.user?.id === user?.user?.id;
    }

    isDoneGivingPoints(user: IParticipant) {
        if (this.isCurrentlyPresenting(user) || this.isCurrentlyWriting(user)) {
            return false;
        }

        return user.allPointsGiven;
    }

}
