import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INote, NoteKind } from '@contracts/models/INote';
import { HeaderService } from '@web/app/layout/header/header.service';
import { AuthService } from '@web/app/services/auth/auth.service';
import { NoteService } from '@web/app/store/me.service';
import { ParticipantService } from '@web/app/store/participant.service';
import { PresenterService } from '@web/app/store/presenter.service';
import { SessionService } from '@web/app/store/session.service';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-session-present',
    templateUrl: './session-present.component.html',
    styleUrls: ['./session-present.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [style({ transform: 'translateY(10%)', opacity: 0 }), animate('200ms  ease-in', style({ transform: 'translateY(0%)', opacity: 1 }))]),
        ])
    ]
})
export class SessionPresentComponent implements OnInit {
    notes$ = this.noteService.entities$;
    selectedNote: INote;
    isCurrentlyPresenting$ = this.presenterService.getPresenter().pipe(map(presenter => presenter?.user?.id === this.authService?.user?.id));

    currentSession$ = this.sessionService.getCurrentSession();
    hasUnpresentedNotes$ = combineLatest([this.isCurrentlyPresenting$, this.notes$]).pipe(
        map(([isPresenting, notes]) => isPresenting || notes.filter(n => !n.presented).length > 0)
    );
    currentParticipant$ = combineLatest([this.currentSession$, this.authService.getCurrentUser(), this.participantService.entities$]).pipe(
        filter(([_, user]) => !!user),
        map(([currentSession, user, participants]) => participants.find(p => p.session.id === currentSession.id && p.user.id === user.id))
    );

    showIntro$ = combineLatest([this.isCurrentlyPresenting$, this.notes$]).pipe(
        map(([isCurrentlyPresenting, notes]) => !isCurrentlyPresenting && notes.filter(n => !n.presented).length > 0)
    );
    showHelp$ = combineLatest([this.isCurrentlyPresenting$, this.notes$]).pipe(
        map(([isCurrentlyPresenting, notes]) => isCurrentlyPresenting && notes.filter(n => n.presented).length === 0)
    );

    constructor(
        private noteService: NoteService,
        private headerService: HeaderService,
        private authService: AuthService,
        private presenterService: PresenterService,
        private participantService: ParticipantService,
        private sessionService: SessionService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        headerService.setHeader('');
    }

    get positiveNotes$() {
        return this.notes$.pipe(map(n => n.filter(note => note.kind === NoteKind.positive)));
    }
    get negativeNotes$() {
        return this.notes$.pipe(map(n => n.filter(note => note.kind === NoteKind.negative)));
    }

    ngOnInit() {}

    onSelectNote(note: INote) {
        this.selectedNote = note;
        this.noteService.update({
            ...note
        });
    }

    goBack() {
        this.currentParticipant$
            .pipe(
                take(1),
                switchMap(participant =>
                    this.participantService.update({
                        ...participant,
                        isDone: false
                    })
                )
            )
            .subscribe(participant => {
                this.router.navigate(['..', 'participant'], {
                    relativeTo: this.activatedRoute
                });
            });
    }
}

