import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@web/app/directives/directives.module';
import { DiscussComponent } from './discuss.component';
import { ActionPointComponent } from './group-discussion/action-point/action-point.component';
import { GroupDiscussionComponent } from './group-discussion/group-discussion.component';



@NgModule({
    declarations: [DiscussComponent, GroupDiscussionComponent, ActionPointComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        DirectivesModule
    ],
    exports: [DiscussComponent]
})
export class DiscussModule { }
