import { Routes } from '@angular/router';

export const routes: Routes = [];


/*
    Frontpage
        - Join Session
        - Create Session
    Session
        - Owner
            - Administer participants
            - Whiteboard
            - Group notes
            - Download report
        - Participant
            - Add/Edit/Remove note
            - Mark as done
            - Show on 'whiteboard'
            - Give group-points

*/
