import { animate, style, transition, trigger } from '@angular/animations';

export const scaleAndFade = trigger('scaleAndFade', [
    transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('.25s cubic-bezier(0.4, 0, 0.2, 1)', style({ transform: 'scale(1)', opacity: 1 }))
    ]),
    transition(':leave', [
        animate('.25s cubic-bezier(0.4, 0, 0.2, 1)', style({ transform: 'scale(0.5)', opacity: 0 }))
    ])
])
export const scaleAndFadeIn = trigger('scaleAndFadeIn', [
    transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('.25s cubic-bezier(0.4, 0, 0.2, 1)', style({ transform: 'scale(1)', opacity: 1 }))
    ])
])