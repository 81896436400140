import { IProject } from '@contracts/models/IProject';
import { IUser } from '@contracts/models/IUser';

export interface ISession {
    id: string;
    project: IProject;
    sessionNumber: number;
    name: string;
    dateCreated: Date;
    owner: IUser;
    mode: SessionMode;
}

export enum SessionMode {
    notes = 'notes',
    points = 'points',
    revealPoints = 'revealPoints',
    discussion = 'discussion'
}
