<div class="note-groups"
     [class.no-groups]="!hasGroups">
    <ng-container *ngIf="hasGroups; else noGroups">



        <app-note-group *ngFor="let group of sortedGroups; trackBy:groupTracker"
                        [@scaleAndFade]
                        [displayPoints]="displayPoints"
                        [canSelectGroups]="isDiscussionMode"
                        [points]="pointsForGroup(group)"
                        (addToThis)="addToGroup.emit($event)"
                        [hasSelectedNotes]="hasSelectedNotes"
                        [group]="group"
                        (removeNote)="removeNote($event)"
                        (selectGroup)="selectGroup.emit($event)"
                        [notes]="notesForGroup(group)">

        </app-note-group>
    </ng-container>
    <form *ngIf="hasSelectedNotes"
          (ngSubmit)="create(groupName.value)"
          [@scaleAndFade]
          class="new-group-form">
        <div class="new-group">
            <div class="form-control form-control--block">
                <mat-form-field>
                    <input class="input100"
                           matInput
                           required
                           name="groupname"
                           #groupName
                           type="text"
                           [placeholder]="'Add '+ selectedNotes.length +' notes to new group'">
                </mat-form-field>

            </div>
            <button color="accent"
                    title="Save"
                    mat-icon-button
                    type="submit">
                <mat-icon>playlist_add</mat-icon>
            </button>
        </div>
    </form>
</div>
<ng-template #noGroups>
    <div class="no-groups-info">
        <div class="no-groups-info__title">
            No groups created yet
        </div>
        <div class="no-groups-info__subtext">
            Select notes from the list to start grouping notes
        </div>
    </div>
</ng-template>