<div class="presented-notes"
     [class.no-notes]="orderedNotes.length === 0">
    <div class="presented-notes__list"
         *ngIf="orderedNotes.length > 0; else noNotes"
         cdkDropList
         [id]="'note-list'">
        <app-presented-note class="presented-notes__list-item isSelectable"
                            *ngFor="let note of orderedNotes"
                            [isSelected]="isSelected(note)"
                            [hasSelectedNotes]="hasSelectedNotes"
                            [note]="note"
                            (click)="select.emit(note)"></app-presented-note>
    </div>

    <ng-template #noNotes>
        <div class="no-notes-info">
            <div class="no-notes-info__title">
                No notes yet!
            </div>
            <div class="no-notes-info__subtext">
                Once participants start presenting notes, they will appear here
            </div>
        </div>
    </ng-template>
</div>