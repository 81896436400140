import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IGroup } from '@contracts/models/IGroup';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { INote } from '@contracts/models/INote';
import { SessionMode } from '@contracts/models/ISession';
import { scaleAndFade } from '@web/app/core/animations';

@Component({
    selector: 'app-gruop-display',
    templateUrl: './gruop-display.component.html',
    styleUrls: ['./gruop-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        scaleAndFade,
        // nice stagger effect when showing existing elements
        trigger('list', [
            transition(':enter', [
                transition(':enter', [])
            ]),
        ]),
        trigger('items', [
            // cubic-bezier for a tiny bouncing feel
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),
                animate('0.3s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(1)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('0.3s cubic-bezier(.8,-0.6,0.2,1.5)',
                    style({ transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px' }))
            ]),
        ])
    ],
})
export class GruopDisplayComponent implements OnInit {
    SessionMode = SessionMode;
    @Input() groups: IGroup[];
    @Input() groupPoints: IGroupPoint[];
    @Input() groupedNotes: IGroupedNote[];
    @Input() sessionMode: SessionMode;

    constructor() { }

    get pointsByGroup() {
        return (this.groups || []).reduce((res: { [groupId: string]: number }, group: IGroup) => ({
            ...res,
            [group.id]: this.groupPoints.filter(g => g.group && g.group.id === group.id).length
        }), {});
    }

    get notesByGroup() {
        return (this.groups || []).reduce((res: { [groupId: string]: INote[] }, group: IGroup) => ({
            ...res,
            [group.id]: this.groupedNotes.filter(g => g.group && g.group.id === group.id).map(g => g.note),
        }), {});
    }

    ngOnInit() {
    }

}
