import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '@web/app/core/local-storage.service';
import { ViewContainerService } from '@web/app/core/view-container.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private localStorageService: LocalStorageService,
        private snackBar: MatSnackBar,
        private viewContainerService: ViewContainerService) {

    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // tslint:disable-next-line:max-line-length
        // const accessToken = this.localStorageService.getString(LocalStorageKey.accessToken);
        // if (accessToken) {
        //     const headers = new HttpHeaders({ 'x-access-token': accessToken });
        //     req = req.clone({
        //         withCredentials: true,
        //         headers: headers
        //     });
        // }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if ([401, 400, 404].indexOf(err.status) > -1 && err.error.message) {
                    this.snackBar.open(err.error.message, 'Okay!', {
                        duration: 3000
                    });
                }
            }
        }));
    }
}
