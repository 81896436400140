import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { NoteListModule } from '@web/app/components/note-list/note-list.module';
import { PageActionsModule } from '@web/app/components/page-actions/page-actions.module';
import { SessionPresentComponent } from './session-present.component';



@NgModule({
    declarations: [SessionPresentComponent],
    exports: [SessionPresentComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        PageActionsModule,
        NoteListModule,
        MatSnackBarModule
    ]
})
export class SessionPresentModule { }
