import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Component, ErrorHandler, NgModule, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { routes } from '@web/app/app.routes';
import { AuthInterceptor } from '@web/app/core/auth.interceptor';
import { PagesModule } from '@web/app/pages/pages.module';
import { SocketIoModule } from '@web/app/services/socket/socket-io.module';
import { reducers } from '@web/app/store/app/app.store';
import { LayoutEffects } from '@web/app/store/app/layout/layout.effects';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { CookieService } from 'ngx-cookie-service';
import * as uuidv4 from 'uuid/v4';
import { PlatformService } from './core/platform.service';
import { HeaderService } from './layout/header/header.service';

export const sentryErrorHandler = Sentry.createErrorHandler({
    showDialog: false,
});

export const sentryAppInitializer = () => () => { };

@Component({
    selector: 'app-root',
    styles: [':host { width: 100%; display: flex; }'],
    template: `
               <router-outlet></router-outlet>
          `
})
export class InitialComponent implements OnInit {
    constructor(private headerService: HeaderService, private platformService: PlatformService) {
        this.setPlatformInfo();
    }
    ngOnInit() {
        const ua = navigator.userAgent.toLowerCase();
        const isMobileDevice = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('android') > -1;
        if (navigator.maxTouchPoints > 1 && isMobileDevice) {
            document.body.classList.add('mobile');
        }
    }

    setPlatformInfo() {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1 && (ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1)) {
            if (ua.indexOf('iphone') !== -1) {
                document.body.classList.add('iphone');
            }
            if (ua.indexOf('ipad') !== -1) {
                document.body.classList.add('ipad');
            }
            if (ua.indexOf('chrome') > -1) {
                document.body.classList.add('chrome');
            } else {
                document.body.classList.add('safari');
            }
        }
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            // We execute the same script as before
            const viewheight = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${viewheight}px`);
        });
    }

}
@NgModule({
    declarations: [
        InitialComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(reducers, {}),
        EffectsModule.forRoot([LayoutEffects]),
        StoreDevtoolsModule.instrument(),
        DataStoreModule,
        MatSnackBarModule,
        HttpClientModule,
        MatSidenavModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        SocketIoModule.forRoot({
            options: {
                'reconnection': true,
                'reconnectionDelay': 1000,
                'reconnectionDelayMax': 5000,
                'reconnectionAttempts': 5
            }
        }),
        MatButtonModule,
        PagesModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }, CookieService,
        {
            provide: ErrorHandler,
            useValue: sentryErrorHandler,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: sentryAppInitializer,
            deps: [Sentry.TraceService],
            multi: true,
        }],
    bootstrap: [InitialComponent],
})
export class AppModule {
    constructor(cookieService: CookieService) {
        const existingCookie = cookieService.get('browser-id');
        if (!existingCookie) {
            cookieService.set('browser-id', uuidv4());
        }
    }
}
