<app-checkbox *ngIf="hasSelectedNotes"
              [checked]="isSelected">

</app-checkbox>
<div class="kind" [class.show-checkbox]="hasSelectedNotes">
    <div class="circle"
         [ngClass]="note.kind"></div>
</div>
<div class="content">
    <div class="text">
        {{ note.text }}
    </div>
    <div class="user">
        {{ note.owner.name }}
    </div>
</div>