<app-session-points-group-list
    [groups]="groups$ | async"
    [points]="points$ | async"
    [groupedNotes]="groupedNotes$ | async"
    [currentSession]="currentSession$ | async"
    (removePoint)="onRemovePoint($event)"
    (addPoint)="onAddPoint($event)"
></app-session-points-group-list>

<app-page-actions>
    <button mat-mini-fab color="primary" class="with-content" (click)="goBack()">
        <mat-icon aria-label="" class="rotate_180">arrow_right_alt</mat-icon> Back to presenting
    </button>
</app-page-actions>

