import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, ViewContainerRef } from '@angular/core';
import { ViewContainerService } from '@web/app/core/view-container.service';

@Component({
  selector: 'app-page-actions',
  templateUrl: './page-actions.component.html',
  styleUrls: ['./page-actions.component.scss'],
  animations: [
    trigger('pageActions', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('0.3s cubic-bezier(.8,-0.6,0.2,1.5)',
          style({ transform: 'translateY(5%)', opacity: 1 }))
      ]),
      transition('false => true', [
        style({ transform: 'translateY(5%)', opacity: 1 }),
        animate('0.3s cubic-bezier(.8,-0.6,0.2,1.5)',
          style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class PageActionsComponent implements OnDestroy {

  isLeaving = false;
  constructor(viewContainerRef: ViewContainerRef, private viewContainerService: ViewContainerService) {
    viewContainerService.setViewContainer(viewContainerRef);
  }

  ngOnDestroy() {
    this.viewContainerService.clearViewContainer();
  }

}
