import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-post-it',
    templateUrl: './post-it.component.html',
    styleUrls: ['./post-it.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PostItComponent implements OnInit {
    @Input() size: 'small' | 'regular' | 'stretch' = 'regular';
    @Input() theme: 'dark' | 'light' | 'neutral' = 'light';
    @Input() rotate: Boolean = true;
    @Input() rotateDeg: number;

    randomRotation = 0;

    ngOnInit() {
        if (this.rotate) {
            const max = 7;
            const deg = Math.random() * (max * 2) - max;
            this.randomRotation = this.rotateDeg !== 0 ? this.rotateDeg : deg;
        }
    }
}

