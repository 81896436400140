import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IActionPoint } from '@contracts/models/IActionPoint';
import { IGroup } from '@contracts/models/IGroup';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { IGroupPoint } from '@contracts/models/IGroupPoint';
import { INote, NoteKind } from '@contracts/models/INote';
import { IParticipant } from '@contracts/models/IParticipant';
import { IPresenter } from '@contracts/models/IPresenter';
import { ISession, SessionMode } from '@contracts/models/ISession';
import { NotePresented, SocketActionTypes } from '@contracts/socket/socket-action';
import { scaleAndFadeIn } from '@web/app/core/animations';
import { HeaderService } from '@web/app/layout/header/header.service';
import { SocketIoService } from '@web/app/services/socket/socket-io.service';
import { ActionPointService } from '@web/app/store/action-points.service';
import { GroupPointService } from '@web/app/store/group-point.service';
import { GroupService } from '@web/app/store/group.service';
import { GroupedNoteService } from '@web/app/store/grouped-note.service';
import { NoteService } from '@web/app/store/me.service';
import { ParticipantService } from '@web/app/store/participant.service';
import { PresenterService } from '@web/app/store/presenter.service';
import { SessionService } from '@web/app/store/session.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-session-owner',
    templateUrl: './session-owner.component.html',
    styleUrls: ['./session-owner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [scaleAndFadeIn]
})
export class SessionOwnerComponent implements OnInit {
    public SessionMode = SessionMode;
    currentSession$: Observable<ISession>;
    participants$: Observable<IParticipant[]>;
    currentPresenter$: Observable<IPresenter>;
    groups$: Observable<IGroup[]>;
    notes$: Observable<INote[]>;
    groupedNotes$: Observable<IGroupedNote[]>;
    displayedNoteKind$ = new BehaviorSubject<NoteKind>(null);
    allLists$: Observable<string[]>;
    groupLists$: Observable<string[]>;
    sessionId$: Observable<string>;
    selectedNotes: INote[];
    selectedGroup: IGroup;
    participantCollapsed: boolean;
    points$: Observable<IGroupPoint[]>;
    actionPoints$: Observable<IActionPoint[]>;
    activeActionPoint$: Observable<IActionPoint>;

    public groupForm = new FormGroup({
        sessionId: new FormControl(''),
        name: new FormControl('')
    });

    constructor(
        private sessionService: SessionService,
        private participantService: ParticipantService,
        private presenterService: PresenterService,
        private groupService: GroupService,
        private groupedNoteService: GroupedNoteService,
        private noteService: NoteService,
        private headerService: HeaderService,
        private actionPointService: ActionPointService,
        groupPointsService: GroupPointService,
        socket: SocketIoService
    ) {
        this.actionPoints$ = this.actionPointService.entities$;
        this.participants$ = this.participantService.entities$;
        this.currentPresenter$ = this.presenterService.getPresenter();
        this.groups$ = groupService.entities$;
        this.points$ = groupPointsService.entities$;
        this.notes$ = combineLatest(this.displayedNoteKind$, noteService.entities$, groupedNoteService.entities$).pipe(
            map(([kind, notes, groupedNotes]) => ({ kind: kind, notes: notes, groupedNotes: groupedNotes.map(g => g.note) })),
            map(({ kind, notes, groupedNotes }) =>
                notes
                    .filter(n => !groupedNotes.find(gn => gn.id === n.id))
                    .filter(n => n.presented)
                    .filter(n => (!!kind ? n.kind === kind : true))
            )
        );
        this.groupedNotes$ = this.groupedNoteService.entities$;
        this.groupLists$ = this.groups$.pipe(map(groups => [...groups.map(group => `list-${group.id}`), 'new-group-drop-zone']));
        this.allLists$ = this.groups$.pipe(map(groups => [...groups.map(group => `list-${group.id}`), 'note-list', 'new-group-drop-zone']));

        socket
            .fromEvent(SocketActionTypes.NotePresented)
            .pipe(map((action: NotePresented) => action.note))
            .subscribe(this.notePresented);
    }
    groupTracker(_, group: IGroup) {
        return group.id;
    }
    ngOnInit() {
        this.currentSession$ = this.sessionService.getCurrentSession().pipe(
            tap(session =>
                this.headerService.setHeader(`${session.name} (${session.sessionNumber})`, {
                    titleUrl: `https://www.retrospective.fun/join?sn=${session.sessionNumber}`
                })
            )
        );
    }
    removeFromPresentedNotes(note: IGroupedNote) {
        this.groupedNoteService.delete(note);
    }
    toggleMode(currentSession: ISession, mode: SessionMode) {
        if (mode !== SessionMode.notes) {
            this.currentPresenter$.pipe(take(1)).subscribe(presenter => this.removePresenter(presenter));
        }
        this.sessionService.update(
            {
                ...currentSession,
                mode
            },
            {
                isOptimistic: false
            }
        );
    }

    stepName(sessionMode: SessionMode) {
        switch (sessionMode) {
            case SessionMode.notes:
                return 'Present notes';
            case SessionMode.points:
                return 'Reveal groups';
            case SessionMode.revealPoints:
                return 'Reveal points';
            case SessionMode.discussion:
                return 'Discuss';
            default:
                return null;
        }
    }

    previousStepName(sessionMode: SessionMode) {
        const step = this.previousStep(sessionMode);
        return this.stepName(step);
    }

    nextStepName(sessionMode: SessionMode) {
        const step = this.nextStep(sessionMode);
        return this.stepName(step);
    }

    currentStepName(sessionMode: SessionMode) {
        return this.stepName(sessionMode);
    }

    nextStep(sessionMode: SessionMode) {
        switch (sessionMode) {
            case SessionMode.notes:
                return SessionMode.points;
            case SessionMode.points:
                return SessionMode.revealPoints;
            case SessionMode.revealPoints:
                return SessionMode.discussion;
            case SessionMode.discussion:
                return null;
        }
    }

    previousStep(sessionMode: SessionMode) {
        switch (sessionMode) {
            case SessionMode.notes:
                return null;
            case SessionMode.points:
                return SessionMode.notes;
            case SessionMode.revealPoints:
                return SessionMode.points;
            case SessionMode.discussion:
                return SessionMode.revealPoints;
        }
    }

    onSelectGroup([selectedGroup, selected]: [IGroup, boolean]) {
        this.groupService.update(
            {
                ...selectedGroup,
                selectedForDiscussion: selected
            },
            {
                isOptimistic: false
            }
        );
    }

    onGroupDescriptionChange([group, description]: [IGroup, string]) {
        this.groupService.update(
            {
                ...group,
                description
            },
            {
                isOptimistic: false
            }
        );
    }

    onSelectNote(note: INote) {
        const alreadySelected = this.selectedNotes && this.selectedNotes.includes(note);
        if (alreadySelected) {
            this.selectedNotes = [...this.selectedNotes.filter(n => n !== note)];
        } else {
            this.selectedNotes = [...(this.selectedNotes || []), note];
        }
    }

    notePresented = (note: INote) => {
        this.noteService.getByKey(note.id);
    };

    onRemoveNote(groupedNote: IGroupedNote) {
        this.groupedNoteService.delete(groupedNote);
    }

    onAddToGroup(group: IGroup) {
        this.selectedNotes
            .map(
                note =>
                    <IGroupedNote>{
                        group,
                        id: undefined,
                        note
                    }
            )
            .forEach(groupdNote => this.groupedNoteService.add(groupdNote));
        this.selectedNotes = [];
    }

    onCreateActionPoint(actionPoint: IActionPoint) {
        this.activeActionPoint$ = this.actionPointService.add(actionPoint, { isOptimistic: false });
    }

    setActiveActionPoint(actionPoint: IActionPoint) {
        this.activeActionPoint$ = of(actionPoint);
    }

    onSaveActionPoint(actionPoint: IActionPoint) {
        if (!actionPoint.title) {
            this.actionPointService.delete(actionPoint, { isOptimistic: false });
        } else {
            this.actionPointService.update(actionPoint, { isOptimistic: false });
        }
    }

    onCreateGroup({ group, notes }: { group: IGroup; notes: INote[] }) {
        this.groupService.add(group, { isOptimistic: false }).subscribe(createdGroup => {
            notes
                .map(
                    note =>
                        <IGroupedNote>{
                            group: createdGroup,
                            id: undefined,
                            note
                        }
                )
                .forEach(groupdNote => this.groupedNoteService.add(groupdNote));
            this.selectedNotes = [];
        });
    }

    removePresenter(presenter: IPresenter) {
        if (!!presenter) this.presenterService.delete(presenter);
    }
    setPresenter(presenter: IPresenter) {
        this.presenterService.add(presenter);
    }

    saveGroup() {
        this.groupService.add(this.groupForm.value, {
            isOptimistic: false
        });
    }

    onToggleKind(kind?: NoteKind) {
        this.displayedNoteKind$.next(kind);
    }
}

