import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    private headerSubject = new ReplaySubject<string>();
    private headerOptions = new ReplaySubject<{ hidden?: boolean }>();
    private titleUrl$ = new ReplaySubject<string>();
    public get header(): Observable<string> {
        return this.headerSubject.asObservable();
    }

    public get titleUrl(): Observable<string> {
        return this.titleUrl$.asObservable();
    }

    public get options() {
        return this.headerOptions.asObservable();
    }
    public setHeader(title: string, options: { hidden?: boolean, titleUrl?: string } = { hidden: false, titleUrl: null }) {
        this.headerSubject.next(title);
        this.headerOptions.next(options);
        this.titleUrl$.next(options?.titleUrl);
    }
}
