import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-palette',
    templateUrl: './palette.component.html',
    styleUrls: ['./palette.component.scss']
})
export class PaletteComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    raiseException() {
        throw new Error('This is an error!');
    }
}
