import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketIoService } from './socket-io.service';

@Injectable()
export class SocketIOEffects {

    constructor(private socketService: SocketIoService) { }

    @Effect()
    mapToAction$ = this.socketService
        .fromEvent('*').pipe(
            map((ev: { data: any[] }) => ev.data),
            map(([type, payload]) => ({ ...payload, type }))
        );

}
