<button mat-icon-button
        [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <ng-container *ngIf="currentSession && isSessionOwner">
        <span mat-menu-item>Session {{ currentSession.sessionNumber }}</span>
        <mat-divider></mat-divider>
    </ng-container>
    <a mat-menu-item
       *ngIf="currentSession && isSessionOwner"
       [routerLink]="['/', 'dashboard']">
        <mat-icon>home</mat-icon>
        <span>Dashboard</span>
    </a>
    <a mat-menu-item
       *ngIf="currentSession && isSessionOwner"
       [routerLink]="['/', 'session', currentSession.id, 'whiteboard']"
       target="_blank">
        <mat-icon>panorama_wide_angle</mat-icon>
        <span>Open whiteboard</span>
    </a>
    <button mat-menu-item
            (click)="logout.next()">
        <mat-icon>undo</mat-icon>
        <span>Log out</span>
    </button>
</mat-menu>