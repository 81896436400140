import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '@contracts/models/IUser';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _user: IUser;

    public get user(): IUser {
        return this._user;
    }

    public set user(user: IUser) {
        this._user = user;
        this.user$.next(this._user);
    }

    public user$ = new ReplaySubject<IUser>();

    constructor(private httpClient: HttpClient) { }

    getCurrentUser(): Observable<IUser> {
        return this.httpClient.get<IUser>('/api/v1/me')
            .pipe(
                tap(user => this.user = user)
            );
    }

    logout(): Observable<any> {
        return this.httpClient.delete('/api/v1/user');
    }
}
