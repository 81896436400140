import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderService } from '../layout/header/header.service';

@Directive({
    selector: '[appInputBox]'
})
export class InputBoxDirective implements OnDestroy, OnInit {

    destroyed$ = new Subject<void>();

    constructor(private elementRef: ElementRef, private headerService: HeaderService) {
    }

    ngOnInit() {
        fromEvent(this.elementRef.nativeElement, 'focus')
            .pipe(takeUntil(this.destroyed$))
            .subscribe(this.setKeyboardOpenedClass);

        fromEvent(this.elementRef.nativeElement, 'blur')
            .pipe(takeUntil(this.destroyed$))
            .subscribe(this.setKeyboardClosedClass);
        this.destroyed$.subscribe(this.setKeyboardClosedClass);

    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    setKeyboardOpenedClass = () => {
        document.body.classList.add('keyboard');
    }

    setKeyboardClosedClass = () => {
        document.body.classList.remove('keyboard');

    }
}
