import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGroupedNote } from '@contracts/models/IGroupedNote';
import { INote } from '@contracts/models/INote';
import { DataStoreModule } from '@web/app/store/data-store.module';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: DataStoreModule
})
export class GroupedNoteService extends EntityCollectionServiceBase<IGroupedNote> {

  constructor(private http: HttpClient, elementsFactory: EntityCollectionServiceElementsFactory) {
    super('GroupedNote', elementsFactory);

  }

  getNotesForGroup(id: string): Observable<INote[]> {
    return this.entities$.pipe(
      map(g => g.filter(group => group.group.id === id).map(group => group.note))
    );
  }


  getGroupedNotesForGroup(id: string): Observable<IGroupedNote[]> {
    return this.entities$.pipe(
      map(g => g.filter(group => group.group.id === id))
    );
  }

}
