import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ForLoop } from '@web/app/pipes/for-loop.pipe';
import { ReadableDatePipe } from './readable-date.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ForLoop, ReadableDatePipe],
  exports: [ForLoop, ReadableDatePipe]
})
export class PipesModule { }
