<div class="session-list">
    <div class="session-list__item"
         [routerLink]="['/', 'session', session.id, 'owner']"
         *ngFor="let session of mySessions">
        <div class="content">
            <span class="name">
                {{ session.name }}</span>
            <span class="date"
                  *ngIf="session.dateCreated">{{ readableDate(session.dateCreated) }} ago</span>
        </div>
        <div class="icon">
            <mat-icon>keyboard_arrow_right
            </mat-icon>
        </div>
    </div>
</div>
<div *ngIf="participatingSessions.length > 0 && showParticipating">
    <strong>Sessions you're participating in</strong>
    <ul>
        <li *ngFor="let session of participatingSessions"><a [routerLink]="['/', 'session', session.id, 'participant']">{{
        session.name }}</a></li>
    </ul>
</div>