import * as layoutActions from './layout.actions';

export interface ILayoutSlice {
    snackbarOpen: boolean;
}

export const layoutSliceInitialState: ILayoutSlice = {
    snackbarOpen: false
};

export function layoutReducer(state = layoutSliceInitialState, action: layoutActions.LayoutActions) {
    switch (action.type) {
        case (layoutActions.LayoutActionTypes.SnackbarOpened): {
            return {
                ...state,
                snackbarOpen: true
            };
        }
        case (layoutActions.LayoutActionTypes.SnackbarClosed): {
            return {
                ...state,
                snackbarOpen: false
            };
        }
        default: return state;
    }
}


export const getSnackbarOpen = (state: ILayoutSlice) => state.snackbarOpen;
